/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

export interface AcquisitionDetails {
  lenderNames: string[];
  acqnDashboardDetails: AcqnDashboardDetails[];
}

export interface AcqnDashboardDetails {
  aqsnDealId?: string;
  aqsnDt: Date;
  bkEntDt: Date;
  coltRefNo?: string;
  complianceRate: string;
  dlNme: string;
  dusDealId?: string;
  esa: string;
  exceptionFlag: boolean;
  id: string;
  lender: string;
  lnBalance?: string;
  maxSubmtDt: Date;
  pca: string;
  propNme: string;
  purpsTypSpmt?: string;
  refType?: string;
  reqdSubmtDt: Date;
  sra: string;
  transType?: string;
  zon: string;
  acquisitionStatus: string;
}

export interface SelectedReports {
  propNme: string;
  pca: string;
  esa: string;
  sra: string;
  zon: string;
  pcaFlag: boolean;
  esaFlag: boolean;
  zonFlag: boolean;
  sraFlag: boolean;

  id: number;
  isPcaMod: boolean;
  isEsaMod: boolean;
  isZonMod: boolean;
  isSraMod: boolean;
}

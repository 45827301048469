import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.css']
})
export class UserNotificationComponent implements OnInit {
  @Input() title = "";
  @Input() message = "";

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from '../../../shared/app.constants';
import { DashboardService } from '../../../services/dashboard.service';
import { SellerServiceNumberService } from '../../../services/sellerservicernumber.service';
import { SellerServicerNumber } from '../../../model/SellerServicerNumber';
import { AcqnDashboardDetails, AcquisitionDetails, SelectedReports } from '../../../model/AcquisitionDetails';
import { SelectItem } from '@fm-ui-adk/components-2.0/common/model';
import * as _ from 'lodash';
import { Utils } from '../../../shared/utils';

@Component({
  selector: 'app-acquisitions',
  templateUrl: './acquisitions.component.html',
  styleUrls: ['./acquisitions.component.css'],
  providers: [DashboardService]
})
export class AcquisitionsComponent implements OnInit {

  // https://angular.io/guide/component-interaction
  @Input() set acqnDetails(acqnDetails: AcquisitionDetails) {
    this._acqnDetails = acqnDetails;
    this.resetDropDownPlaceHolders('tabChange');
    this.loadAcquistionTabData();
  }
  private _acqnDetails: AcquisitionDetails;

  @Input() selectStartPeriod: any[] = [];
  @Input() selectedStartPeriod: string = "";
  @Input() selectEndPeriod: any[] = [];
  @Input() selectedEndPeriod: string = "";

  @Input() numberOfAcquisitions: any;
  @Input() complianceRateAcquisitions: any;
  @Input() submissionRateAcquisitions: any;
  @Input() isInternal: boolean = false;
  @Input() showAcqnTableEditCheckbox: boolean = false;
  @Output() refreshAcqnDetails: EventEmitter<object> = new EventEmitter<object>();
  @Output() getEndPeriodDates: EventEmitter<object> = new EventEmitter<object>();

  numberOfPreAcquisitionDeals: number = 0;

  showAcqnUpdateComponent = false;

  acqnDetailsDashboardDisplay: AcqnDashboardDetails[] = [];
  acqnDetailsDashboardDisplayTemp: AcqnDashboardDetails[];

  acqsnLenderList: any[] = [];
  acqsnSelectedLender: string = '';
  acquisitionStatusFilterList: any[] = [];
  acquisitionStatusFilterSelected: string = '';
  lnPurpsTypeList: SelectItem[] = [];
  lnPurpsTypeSelected: string = '';
  lnRefiTypeList: SelectItem[] = [];
  lnRefiTypeSelected: string = '';
  lnBalanceList: SelectItem[] = [];
  lnBalanceSelected: string = '';
  txTypeList: SelectItem[] = [];
  txTypeSelected: string = '';

  selectedReportArrayDisplay: SelectedReports[] = [];
  cols5: any[];

  constructor(private dashboardService: DashboardService,
    private sellerServiceNumberService: SellerServiceNumberService,
    private utils: Utils) {
  }

  ngOnInit(): void {

    let tempDate = new Date();
    this.selectedEndPeriod = tempDate.toDateString();

    this.cols5 = [
      { field: 'dusDealId', header: 'DUS Gateway Deal ID' },
      { field: 'aqsnDealId', header: 'Acquisition Deal ID' },
      { field: 'dlNme', header: 'Acquisition Deal Name' },
      { field: 'coltRefNo', header: 'Collateral Reference #' },
      { field: 'propNme', header: 'Property Name' },
      { field: 'aqsnDt', header: '(Est.) Acquisition Date' },
      { field: 'pca', header: 'PCA', width: '6%' },
      { field: 'esa', header: 'ESA', width: '6%' },
      { field: 'zon', header: 'ZON', width: '6%' },
      { field: 'sra', header: 'SRA', width: '6%' },
      { field: 'reqdSubmtDt', header: 'Required Submission Date' },
      { field: 'maxSubmtDt', header: 'Last File Submitted Date' },
      { field: 'complianceRate', header: 'Compliance Assessment' }
    ];
  }

  public loadAcquistionTabData() {
    if (this._acqnDetails.acqnDashboardDetails) {
      this.acqnDetailsDashboardDisplay = this._acqnDetails.acqnDashboardDetails;
      this.acqnDetailsDashboardDisplayTemp = this._acqnDetails.acqnDashboardDetails;
      this.getAcquisitionDashboardCounts();
    } else {
      this.acqnDetailsDashboardDisplay = [];
      this.numberOfAcquisitions = 0;
      this.complianceRateAcquisitions = 0;
      this.submissionRateAcquisitions = 0;
    }

    this.selectedReportArrayDisplay = [];
    this.loadDropDownFilterLists();
  }

  public getAcquisitionDashboardCounts() {
    if (this.acquisitionStatusFilterSelected == 'Pre-Acquisition') {
      this.numberOfAcquisitions = 'N/A';
      this.complianceRateAcquisitions = 'N/A';
      this.submissionRateAcquisitions = 'N/A';
    } else {
      // get number of pre-acquisition deals
      this.numberOfPreAcquisitionDeals = _.filter(this.acqnDetailsDashboardDisplay, function (o) { return o.acquisitionStatus == 'Pre-Acquisition' }).length;

      // get number of acquisitions by finding how many acquisition deal ids
      this.numberOfAcquisitions = _.filter(this.acqnDetailsDashboardDisplay, function (o) { return o.acquisitionStatus == 'Acquired' }).length;

      // get report counts
      let submittedCount = 0;
      let pcaNCount = 0;
      let esaNCount = 0;
      let zonNCount = 0;
      let sraNCount = 0;
      let compliantCount = 0;
      let nonCompliantCount = 0;
      for (let i = 0; i < this.acqnDetailsDashboardDisplay.length; i++) {
        //skip if pre-acquisition deal
        if (this.acqnDetailsDashboardDisplay[i].acquisitionStatus == 'Acquired') {
          //PCA
          if (this.acqnDetailsDashboardDisplay[i].pca == 'Y') {
            submittedCount++;
          } else if (this.acqnDetailsDashboardDisplay[i].pca == 'N') {
            pcaNCount++;
          }
          //ESA
          if (this.acqnDetailsDashboardDisplay[i].esa == 'Y') {
            submittedCount++;
          } else if (this.acqnDetailsDashboardDisplay[i].esa == 'N') {
            esaNCount++;
          }
          //ZON
          if (this.acqnDetailsDashboardDisplay[i].zon == 'Y') {
            submittedCount++;
          } else if (this.acqnDetailsDashboardDisplay[i].zon == 'N') {
            zonNCount++;
          }
          //SRA
          if (this.acqnDetailsDashboardDisplay[i].sra == 'Y') {
            submittedCount++;
          } else if (this.acqnDetailsDashboardDisplay[i].sra == 'N') {
            sraNCount++;
          }
          //Compliant
          switch (this.acqnDetailsDashboardDisplay[i].complianceRate.toLowerCase()) {
            case "compliant":
              compliantCount++;
              break;
            case "non-compliant":
              nonCompliantCount++;
              break;
            default:
              break;
          }
        }
      }
      // get compliance rate 
      if (compliantCount + nonCompliantCount != 0)
        this.complianceRateAcquisitions = compliantCount / (compliantCount + nonCompliantCount);
      else
        this.complianceRateAcquisitions = 0;

      // get submission rate
      if (submittedCount + pcaNCount + esaNCount + zonNCount + sraNCount != 0)
        this.submissionRateAcquisitions = submittedCount / (submittedCount + pcaNCount + esaNCount + zonNCount + sraNCount);
      else
        this.submissionRateAcquisitions = 0;
    }
  }

  public resetDropDownPlaceHolders(field: string) {
    if (field == 'purpsTypSpmt') {
      this.lnRefiTypeSelected = '';
      this.lnBalanceSelected = '';
      this.txTypeSelected = '';
    } else if (field == 'refType') {
      this.lnPurpsTypeSelected = '';
      this.lnBalanceSelected = '';
      this.txTypeSelected = '';
    } else if (field == 'lnBalance') {
      this.lnPurpsTypeSelected = '';
      this.lnRefiTypeSelected = '';
      this.txTypeSelected = '';
    } else if (field == 'transType') {
      this.lnPurpsTypeSelected = '';
      this.lnRefiTypeSelected = '';
      this.lnBalanceSelected = '';
    } else if (field == 'acquisitionStatus') {
      this.acqsnSelectedLender = '';
      this.lnPurpsTypeSelected = '';
      this.lnRefiTypeSelected = '';
      this.lnBalanceSelected = '';
      this.txTypeSelected = '';
    } else if (field == 'tabChange') {
      this.acqsnSelectedLender = '';
      this.acquisitionStatusFilterSelected = '';
      this.lnPurpsTypeSelected = '';
      this.lnRefiTypeSelected = '';
      this.lnBalanceSelected = '';
      this.txTypeSelected = '';
    } else {
      this.acquisitionStatusFilterSelected = '';
      this.lnPurpsTypeSelected = '';
      this.lnRefiTypeSelected = '';
      this.lnBalanceSelected = '';
      this.txTypeSelected = '';
    }
  }

  public loadDropDownFilterLists() {
    // Lender Names
    if (this._acqnDetails.lenderNames) {
      this.acqsnLenderList = [{ label: 'All', value: '' }];
      if (this._acqnDetails.lenderNames) {
        for (let i = 0; i < this._acqnDetails.lenderNames.length; i++) {
          this.acqsnLenderList.push({ label: this._acqnDetails.lenderNames[i], value: this._acqnDetails.lenderNames[i] });
        }
        this.acqsnLenderList = _.sortBy(this.acqsnLenderList, ['value']);
      }
    } else {
      this.acqsnLenderList = [{ label: 'All', value: '' }];
    }

    // Acquisition Dashboard Details
    if (this._acqnDetails.acqnDashboardDetails) {
      let tempVar: any[];

      // acquisition status
      this.acquisitionStatusFilterList = [
        { label: 'All', value: '' },
        { label: 'Acquired', value: 'Acquired' },
        { label: 'Pre-Acquisition', value: 'Pre-Acquisition' }
      ];

      // loan purpose type(s)
      this.lnPurpsTypeList = [{ label: 'All', value: '' }];
      tempVar = [...new Set(this._acqnDetails.acqnDashboardDetails.map((o) => o.purpsTypSpmt))];
      for (let i = 0; i < tempVar.length; i++) {
        if (tempVar[i] != null) {
          this.lnPurpsTypeList.push({ label: tempVar[i], value: tempVar[i] });
        }
        else if (tempVar[i] == null || tempVar[i] == '') {
          this.lnPurpsTypeList.push({ label: '-- Not Provided --', value: null });
        }
      }

      // loan refinance type(s)
      this.lnRefiTypeList = [{ label: 'All', value: '' }];
      tempVar = [...new Set(this._acqnDetails.acqnDashboardDetails.map((o) => o.refType))];
      for (let i = 0; i < tempVar.length; i++) {
        if (tempVar[i] != null) {
          this.lnRefiTypeList.push({ label: tempVar[i], value: tempVar[i] });
        }
        else if (tempVar[i] == null || tempVar[i] == '') {
          this.lnRefiTypeList.push({ label: '-- Not Provided --', value: null });
        }
      }

      // loan balance indicator(s)
      this.lnBalanceList = [{ label: 'All', value: '' }];
      tempVar = [...new Set(this._acqnDetails.acqnDashboardDetails.map((o) => o.lnBalance))];
      for (let i = 0; i < tempVar.length; i++) {
        if (tempVar[i] != null) {
          this.lnBalanceList.push({ label: tempVar[i], value: tempVar[i] });
        }
        else if (tempVar[i] == null || tempVar[i] == '') {
          this.lnBalanceList.push({ label: '-- Not Provided --', value: null });
        }
      }

      // transaction type(s)
      this.txTypeList = [{ label: 'All', value: '' }];
      tempVar = [...new Set(this._acqnDetails.acqnDashboardDetails.map((o) => o.transType))];
      for (let i = 0; i < tempVar.length; i++) {
        if (tempVar[i] != null) {
          this.txTypeList.push({ label: tempVar[i], value: tempVar[i] });
        }
        else if (tempVar[i] == null || tempVar[i] == '') {
          this.txTypeList.push({ label: '-- Not Provided --', value: null });
        }
      }
      //this.txTypeList = _.sortBy(this.txTypeList, ['value']);
    } else {
      this.lnPurpsTypeList = [{ label: 'All', value: '' }];
      this.lnRefiTypeList = [{ label: 'All', value: '' }];
      this.lnBalanceList = [{ label: 'All', value: '' }];
      this.txTypeList = [{ label: 'All', value: '' }];
    }
  }

  public applyFilters() {
    this.acqnDetailsDashboardDisplay = this._acqnDetails.acqnDashboardDetails;

    //acquisition status
    if (this.acquisitionStatusFilterSelected != '')
      this.acqnDetailsDashboardDisplay = _.filter(this.acqnDetailsDashboardDisplay, ['acquisitionStatus', this.acquisitionStatusFilterSelected])

    //lender
    if (this.acqsnSelectedLender != '')
      this.acqnDetailsDashboardDisplay = _.filter(this.acqnDetailsDashboardDisplay, ['lender', this.acqsnSelectedLender])

    //supplemental
    if (this.lnPurpsTypeSelected != '')
      this.acqnDetailsDashboardDisplay = _.filter(this.acqnDetailsDashboardDisplay, ['purpsTypSpmt', this.lnPurpsTypeSelected])

    //loan refinance type
    if (this.lnRefiTypeSelected != '')
      this.acqnDetailsDashboardDisplay = _.filter(this.acqnDetailsDashboardDisplay, ['refType', this.lnRefiTypeSelected])

    //small loan balance
    if (this.lnBalanceSelected != '')
      this.acqnDetailsDashboardDisplay = _.filter(this.acqnDetailsDashboardDisplay, ['lnBalance', this.lnBalanceSelected])

    //transaction type
    if (this.txTypeSelected != '')
      this.acqnDetailsDashboardDisplay = _.filter(this.acqnDetailsDashboardDisplay, ['transType', this.txTypeSelected])

    //recalculate dashboard metrics
    this.getAcquisitionDashboardCounts();
  }

  // Do a callback and refresh the acqnDetails
  public onRefreshAcqnDetails() {
    // Get start and end date and do a callback to get a new list
    let startDate: string = this.selectedStartPeriod;
    let endDate: string = this.selectedEndPeriod;
    let isLenderRanking: boolean = false;

    this.refreshAcqnDetails.emit({ startDate, endDate, isLenderRanking });
  }

  onGetEndPeriodDates() {
    // Get start and end date and do a callback to get a new list
    let startDate: string = this.selectedStartPeriod;
    let endDate: string = this.selectedEndPeriod;
    let isLenderRanking: boolean = false;
    let onload: boolean = false;

    this.getEndPeriodDates.emit({ startDate, endDate, isLenderRanking, onload });
  }

  showUpdateReportComponent() {
    this.showAcqnUpdateComponent = true;
  }

  onAcqnUpdateTableClose(refresh: boolean) {
    this.showAcqnUpdateComponent = false;
    if (refresh) {
      this.onRefreshAcqnDetails();
    }
  }
}

<div>
    <!-- Compliance Rate and Submission Rate -->
    <div id="lenderRankingSummaryTop">
        <div id="lenderRankingSummaryTiles">
            <fm-cards class="lrTilesMain">
                <div class="count-value">
                    {{complianceRateLenderRanking | percent}}
                </div>
                Compliance Rate
            </fm-cards>
            <fm-cards class="lrTiles">
                <div class="count-value">
                    {{submissionRateLenderRanking | percent}}
                </div>
                Submission Rate
            </fm-cards>
        </div>
    </div>

    <!-- From and To -->
      <div id="lenderRankingPeriodSelection">
        <div id="lenderRankingPeriodSelectionStart" class="drop-down-selection">
          <b class="drop-down-label">From</b>
          <fm-select [options]="selectStartPeriod" [(ngModel)]="selectedStartPeriod"
            placeholder="" (onChange)="onGetEndPeriodDates()"></fm-select>
        </div>
        <div id="lenderRankingPeriodSelectionEnd" class="drop-down-selection">
          <b class="drop-down-label">To</b>
          <fm-select [options]="selectEndPeriod" [(ngModel)]="selectedEndPeriod" (onChange)="onRefreshAcqnDetails()"
            placeholder=""></fm-select>
        </div>
      </div>

    <!-- Table -->
    <div id="print-preview-lender-ranking">
        <div id="lenderRankingTable" class="submittedFilesTable">
          <fm-table #lenderrankingtbl [columns]="lenderRankingTableColumns" [value]="lenderRankingTableDisplay"
            sortMode="single" [loadingIcon]="true" [paginator]="true" [rows]="30" [rowsPerPageOptions]="[25,30,50,75,100,125,150,200]">
            <ng-template fmTemplate="caption">
              <div style="text-align: left">
                <input placeholder="Type to filter" style="width:auto" fmTextInput type="text"
                  [(ngModel)]="lenderRankingFilterBinding"
                  (input)="lenderrankingtbl.filterGlobal($event.target.value, 'contains')" />
              </div>
            </ng-template>
            <ng-template fmTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [fmSortableColumn]="col.field" [style.width]="col.width">{{col.header}}
                  <fm-sortIcon [field]="col.field"></fm-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template fmTemplate="body" let-rowData let-columns="columns">
              <tr align="center">
                <td>
                  {{rowData['lenderName']}}
                </td>
                <td>
                  {{rowData['complianceRate'] | percent}}
                </td>
                <td>
                  {{rowData['submissionRate'] | percent}}
                </td>
              </tr>
            </ng-template>
          </fm-table>
        </div>
      </div>
      <br>

</div>
/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetails } from "../model/UserDetails";
import { ApiService } from "../services/api.service";
import { AppConstants } from "../shared/app.constants";
import { LaunchDarklyUtil } from '../shared/launch-darkly-util';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  homeTitle: string;
  homeUnauthorizedMessage: string;
  userDetails: UserDetails;
  showRentRollDashboard = false;
  showDueDiligenceDashboard = false;
  showOperatingStatementsDashboard = false;
  unauthorizedUser = true;

  // client: LDClient.LDClient;
  systemDownFlagValue = {};
  showSystemDown = false;

  public constructor(private router: Router, private apiService: ApiService,
    private launchDarkly: LaunchDarklyUtil) {
  }

  ngOnInit() {

    this.initLaunchDarkly();

    //Set constants
    this.homeTitle = AppConstants.HOME_TITLE;
    this.homeUnauthorizedMessage = AppConstants.HOME_UNAUTHORIZED_MESSAGE;

    this.userDetails = this.apiService.getUserDetails();

    if (this.userDetails) {
      let roles: any[] = this.userDetails.groups;
      if (roles && roles.length > 0) {
        roles.forEach(role => {
          let roleName: String = role.toString();
          if ((roleName.indexOf('SG-GA6-MFDIGITIZER-UAT-MFASSETMGR-ROLE') == 0) ||
            (roleName.indexOf('SG-GA6-MFDIGITIZER-PROD-MFASSETMGR-ROLE') == 0) ||
            (roleName.indexOf('SG-GA6-MFDIGITIZER-UAT-MA-ROLE') == 0) ||
            (roleName.indexOf('SG-GA6-MFDIGITIZER-PROD-MA-ROLE') == 0)) {
            // Data Files
            this.showDueDiligenceDashboard = true;
          } else if (roleName.indexOf('SG-GA6-MFDIGITIZER') == 0) {
            //internal
            this.showDueDiligenceDashboard = true;
            this.showRentRollDashboard = true;
            this.showOperatingStatementsDashboard = true;
          } else if (roleName.indexOf('MFDST_MFDSTDN_DIGITIZER_RENTROLL') == 0) {
            //Rent Roll
            this.showRentRollDashboard = true;
          } else if (roleName.indexOf('MFDST_MFDSTDN_DIGITIZER_OS') == 0) {
            //Operating Statement
            this.showOperatingStatementsDashboard = true;
          } else if (roleName.indexOf('MFDST_MFDSTDN_DIGITIZER_B2B') == 0) {
            //B2B User -- DO NOTHING
            //console.log("B2B User Role...");
          } else if (roleName.indexOf('MFDST_MFDSTDN_DIGITIZER') == 0) {
            // Data Files
            this.showDueDiligenceDashboard = true;
          }
        });
      }
    }

    this.checkUnauthorizedUser();
    this.defaultRoute();
  }

  checkUnauthorizedUser() {
    if (!this.showDueDiligenceDashboard && !this.showRentRollDashboard && !this.showOperatingStatementsDashboard)
      this.unauthorizedUser = true;
    else
      this.unauthorizedUser = false;
  }

  defaultRoute() {
    if (this.showDueDiligenceDashboard && !this.showRentRollDashboard && !this.showOperatingStatementsDashboard) {
      this.navigateToDDRDashboard();
    } else if (!this.showDueDiligenceDashboard && this.showRentRollDashboard && !this.showOperatingStatementsDashboard) {
      this.navigateToRentRollDashboard();
    } else if (!this.showDueDiligenceDashboard && !this.showRentRollDashboard && this.showOperatingStatementsDashboard) {
      this.navigateToOSRDashboard();
    }
  }

  navigateToRentRollDashboard() {
    this.router.navigate(['/rr_dashboard'], { skipLocationChange: true });
  }

  navigateToDDRDashboard() {
    this.router.navigate(['/dd_dashboard'], { skipLocationChange: true });
  }

  navigateToOSRDashboard() {
    this.router.navigate(['/os_dashboard'], { skipLocationChange: true });
  }

  initLaunchDarkly() {
    this.launchDarkly.getLaunchDarklyShutDown(this, this.launchDarklyCallback)
  }

  launchDarklyCallback(systemDownFlagValue: boolean, caller) {
    caller.systemDownFlagValue = systemDownFlagValue;
    console.log('LaunchDarkly flag systemDownFlagValue = ', caller.systemDownFlagValue);
    if (Object.keys(caller.systemDownFlagValue).length > 0) {
      caller.showSystemDown = true;
      caller.showDueDiligenceDashboard = false;
      caller.showRentRollDashboard = false;
      caller.showOperatingStatementsDashboard = false;
      caller.unauthorizedUser = false;
    }
  }
}

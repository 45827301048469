/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {apiEndpoints, environment} from '../../environments/environment';
import {Injectable} from '@angular/core';

const apiSpringBootPath = environment.baseUrl + '/mfdstcf/mfdstdn';
const endPoints = {
  'cdxUserInfoUrl': '/userdetails?extended=true',
  'getSubmittedFilesData': '/getSubmittedFilesData',
  'getAcquisitionDashboardList' : '/getAcquisitionDealDetails',
  'getFileStatus': '/getFileStatus',
  'getSellerServicerData': '/getsellerservicerdata',
  'rentRollDetails': '/initialRentRollDetails',
  'rentRollSponsorPropertyList': '/reportedPropertiesList',
  'rentRollReport': '/rentRollReport',
  'operatingStatementDetails': '/operatingStatementDetails',
  'operatingStatementReport': '/operatingStatementReport',
  'upfUploadUrl': '/cdxapi/fileupload/getuploadurl',
  'upfStatusUrl': '/cdxapi/fileupload/status/',
  'cdxUploadUrl': '/cdx-filupldproc/fileupload/processing/upload/url',
  'cdxStatusUrl': '/cdx-filupldproc/fileupload/processing/status/',
  'operatingStmtsSponsorPropertyList':'/operatingStatementReportedPropertiesList',
  'setAcquisitionReportFlags':'/setAcquisitionReportFlags'
};

@Injectable()
export class EndpointsService {

  constructor() {
  }

  setApiPath() {
    if (environment.environmentName == "mock") {
      return environment.baseUrl;
    }
    else if (environment.hostName == "localhost") {
      return 'http://localhost:5000/mfdstdn';
    } else {
      return apiSpringBootPath;
    }
  }

  getUploadEndpoint() {
    if (environment.hostName.includes("localhost")) {
      return environment.uploadEndpointDev;
    } else if (environment.hostName.includes("devl")) {
      return environment.uploadEndpointDev;
    } else if (environment.hostName.includes("test")) {
      return environment.uploadEndpointTest;
    } else if (environment.hostName.includes("mfdstdn-01.acpt")) {
      return environment.uploadEndpointAcpt;
    } else if (environment.hostName.includes("dusdatadigitizer")) {
      return environment.uploadEndpointProd;
    }
  }

  logoutFromApp() {
    if (environment.hostName.includes("localhost")) {
      return apiEndpoints['mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com'].logoutUrl;
    } else {
      return apiEndpoints[window.location.hostname].logoutUrl;
    }
  }

  getSellerServicerEndpoint() {
    return this.getEndpoints().getSellerServicerData;
  }

  getEndpoints() {
    return endPoints;
  }
}

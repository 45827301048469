/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

export const apiEndpoints = {
  'mfdstdn.cdxms.devl-etss.aws.fanniemae.com': {
    'loginUrl': 'https://mfdstdn.cdxms.devl-etss.aws.fanniemae.com/mfdstcf',
    'redirectUri': '&redirect_uri=https://mfdstdn.cdxms.devl-etss.aws.fanniemae.com/',
    'logoutUrl': 'https://fmsso-devl.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com': {
    'loginUrl': 'https://mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com/mfdstcf?cdxRedirectPath=/',
    'redirectUri': '&redirect_uri=https://mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com/oauth2/idpresponse?redirectTo=https://mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com/',
    'logoutUrl': 'https://fmsso-devl.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstdn.cdxms.test-etss.aws.fanniemae.com': {
    'loginUrl': 'https://mfdstdn.cdxms.test-etss.aws.fanniemae.com/mfdstcf',
    'redirectUri': '&redirect_uri=https://mfdstdn.cdxms.test-etss.aws.fanniemae.com/',
    'logoutUrl': 'https://fmsso-test.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstcf.mfdstdn.mfbu.test.intgfanniemae.com': {
    'loginUrl': 'https://mfdstcf.mfdstdn.mfbu.test.intgfanniemae.com/mfdstcf?cdxRedirectPath=/',
    'redirectUri': '&redirect_uri=https://mfdstcf.mfdstdn.mfbu.test.intgfanniemae.com/oauth2/idpresponse?redirectTo=https://mfdstcf.mfdstdn.mfbu.test.intgfanniemae.com/',
    'logoutUrl': 'https://fmsso-test.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstdn.cdxms.acpt-etss.aws.fanniemae.com': {
    'loginUrl': 'https://mfdstdn.cdxms.acpt-etss.aws.fanniemae.com/mfdstcf/?cdxRedirectPath=/',
    'redirectUri': '&redirect_uri=https://mfdstdn.cdxms.acpt-etss.aws.fanniemae.com/',
    'logoutUrl': 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstcf.mfdstdn.mfbu.acpt.intgfanniemae.com': {
    'loginUrl': 'https://mfdstcf.mfdstdn.mfbu.acpt.intgfanniemae.com/mfdstcf/?cdxRedirectPath=/',
    'redirectUri': '&redirect_uri=https://mfdstcf.mfdstdn.mfbu.acpt.intgfanniemae.com/oauth2/idpresponse?redirectTo=https://mfdstcf.mfdstdn.mfbu.acpt.intgfanniemae.com/',
    'logoutUrl': 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping'
  },
  'dusdatadigitizer.acptfanniemae.com': {
    'loginUrl': 'https://dusdatadigitizer.acptfanniemae.com/mfdstcf/?cdxRedirectPath=/',
    'redirectUri': '&redirect_uri=https://dusdatadigitizer.acptfanniemae.com/oauth2/idpresponse?redirectTo=https://dusdatadigitizer.acptfanniemae.com/',
    'logoutUrl': 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping'
  },
  'dusdatadigitizer.fanniemae.com': {
    'loginUrl': 'https://dusdatadigitizer.fanniemae.com/mfdstcf/?cdxRedirectPath=/',
    'redirectUri': '&redirect_uri=https://dusdatadigitizer.fanniemae.com/oauth2/idpresponse?redirectTo=https://dusdatadigitizer.fanniemae.com/',
    'logoutUrl': 'https://fmsso.fanniemae.com/idp/startSLO.ping'
  }
};

const apiBaseUrl = 'https://mfdstdn-01-webapp-eb.mfdstdn.devl-mfbu.aws.fanniemae.com';

export const logoutEndPoints = {
  'mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com': {
    'logoutUrl': 'https://fmsso-devl.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstcf.mfdstdn.mfbu.test.intgfanniemae.com': {
    'logoutUrl': 'https://fmsso-test.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstcf.mfdstdn.mfbu.acpt.intgfanniemae.com': {
    'logoutUrl': 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping'
  }
};

export const launchDarklyKeys = {
  'localhost': {
    'sdkKey': 'sdk-f9ce16f8-d264-4a25-b1f2-40ff0b261956',
    'clientId': '6511991c849c181234e26379'
  },
  'mfdstdn.cdxms.devl-etss.aws.fanniemae.com': {
    'sdkKey': 'sdk-f9ce16f8-d264-4a25-b1f2-40ff0b261956',
    'clientId': '6511991c849c181234e26379'
  },
  'mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com': {
    'sdkKey': 'sdk-f9ce16f8-d264-4a25-b1f2-40ff0b261956',
    'clientId': '6511991c849c181234e26379'
  },
  'mfdstdn.cdxms.test-etss.aws.fanniemae.com': {
    'sdkKey': 'sdk-d1a6c8ae-1776-44f2-a012-cc5593ce31cd',
    'clientId': '65119902577203123d394ac2'
  },
  'mfdstcf.mfdstdn.mfbu.test.intgfanniemae.com': {
    'sdkKey': 'sdk-d1a6c8ae-1776-44f2-a012-cc5593ce31cd',
    'clientId': '65119902577203123d394ac2'
  },
  'mfdstdn.cdxms.acpt-etss.aws.fanniemae.com': {
    'sdkKey': 'sdk-bfc87249-97bc-4dda-b142-918aa1fbc355',
    'clientId': '6511992933a791132a233ac6'
  },
  'mfdstcf.mfdstdn.mfbu.acpt.intgfanniemae.com': {
    'sdkKey': 'sdk-bfc87249-97bc-4dda-b142-918aa1fbc355',
    'clientId': '6511992933a791132a233ac6'
  },
  'dusdatadigitizer.acptfanniemae.com': {
    'sdkKey': 'sdk-bfc87249-97bc-4dda-b142-918aa1fbc355',
    'clientId': '6511992933a791132a233ac6'
  },
  'dusdatadigitizer.fanniemae.com': {
    'sdkKey': 'sdk-84dd676d-aa67-48a0-b43b-c77d4ae81d12',
    'clientId': '65119902577203123d394ac3'
  }
};

export const environment = {
  environmentName: 'production',
  production: true,
  baseUrl: 'https://' + window.location.hostname,
  apiBasePath: apiBaseUrl,
  apiUrl: '',
  hostName: window.location.hostname,
  uploadEndpointDev: 'https://mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com',
  uploadEndpointTest: 'https://mfdstcf.mfdstdn.mfbu.test.intgfanniemae.com',
  uploadEndpointAcpt: 'https://mfdstcf.mfdstdn.mfbu.acpt.intgfanniemae.com',
  uploadEndpointProd: 'https://dusdatadigitizer.fanniemae.com',
  logoutEndpointDev: 'https://fmsso-devl.fanniemae.com/idp/startSLO.ping',
  logoutEndpointTest: 'https://fmsso-test.fanniemae.com/idp/startSLO.ping',
  logoutEndpointAcpt: 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping',
  logoutEndpointProd: 'https://fmsso.fanniemae.com/idp/startSLO.ping'
};



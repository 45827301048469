import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LenderRankingTable, lenderRankingTableDisplay } from '../../../model/LenderRankingTable';
import { AcquisitionDetails } from '../../../model/AcquisitionDetails';
import * as _ from 'lodash';

@Component({
  selector: 'app-lender-ranking',
  templateUrl: './lender-ranking.component.html',
  styleUrls: ['./lender-ranking.component.css']
})
export class LenderRankingComponent implements OnInit {

  // https://angular.io/guide/component-interaction
  @Input() set acqnDetails(acqnDetails: AcquisitionDetails) {
    this.lenderRankingTabDetails = acqnDetails;
    this.loadTable();
  }

  @Input() selectStartPeriod: any[] = [];
  @Input() selectedStartPeriod: string = "";
  @Input() selectEndPeriod: any[] = [];
  @Input() selectedEndPeriod: string = "";
  @Output() refreshAcqnDetails: EventEmitter<object> = new EventEmitter<object>();
  @Output() getEndPeriodDates: EventEmitter<object> = new EventEmitter<object>();

  //lender ranking variables
  lenderRankingTableColumns: any[];
  lenderRankingTableDisplay: lenderRankingTableDisplay[] = [];
  lenderRankingTable: LenderRankingTable[] = [];
  lenderRankingTabDetails: AcquisitionDetails;
  ySumPCA: number = 0;
  nSumPCA: number = 0;
  naSumPCA: number = 0;
  ySumESA: number = 0;
  nSumESA: number = 0;
  naSumESA: number = 0;
  ySumSRA: number = 0;
  nSumSRA: number = 0;
  naSumSRA: number = 0;
  ySumZON: number = 0;
  nSumZON: number = 0;
  naSumZON: number = 0;
  compliant: number = 0;
  nonCompliant: number = 0;
  complianceRateLenderRanking: number = 0;
  submissionRateLenderRanking: number = 0;
  totalY: number = 0;
  totalYN: number = 0;


  constructor() { }

  ngOnInit(): void {
    //Lender Ranking Tab variables
    this.lenderRankingTableColumns = [
      { field: "lenderName", header: "Lender Name", width: "50%" },
      { field: "complianceRate", header: "Compliance Rate", width: "25%" },
      { field: "submissionRate", header: "Submission Rate", width: "25%" }
    ];

  }

  //lender ranking methods
  public resetValues() {
    this.ySumPCA = 0;
    this.nSumPCA = 0;
    this.naSumPCA = 0;
    this.ySumESA = 0;
    this.nSumESA = 0;
    this.naSumESA = 0;
    this.ySumSRA = 0;
    this.nSumSRA = 0;
    this.naSumSRA = 0;
    this.ySumZON = 0;
    this.nSumZON = 0;
    this.naSumZON = 0;
    this.compliant = 0;
    this.nonCompliant = 0;
    this.complianceRateLenderRanking = 0;
    this.submissionRateLenderRanking = 0;
    this.totalY = 0;
    this.totalYN = 0;
    this.lenderRankingTable = [];
  }

  public loadTable() {
    this.resetValues();
    if (this.lenderRankingTabDetails.acqnDashboardDetails) {
      for (let i = 0; i < this.lenderRankingTabDetails.acqnDashboardDetails.length; i++) {
        //skip if pre-acquisition deal
        if (this.lenderRankingTabDetails.acqnDashboardDetails[i].acquisitionStatus == 'Acquired') {
          let tempIndex = _.findIndex(this.lenderRankingTable, ['lenderName', this.lenderRankingTabDetails.acqnDashboardDetails[i].lender]);
          if (tempIndex != -1) {
            this.getReportCounts(i, tempIndex);
          } else {
            tempIndex = this.lenderRankingTable.length;
            this.lenderRankingTable.push({
              lenderName: this.lenderRankingTabDetails.acqnDashboardDetails[i].lender,
              complianceRate: 0,
              submissionRate: 0,
              y: 0,
              yn: 0,
              yPCA: 0,
              nPCA: 0,
              naPCA: 0,
              yESA: 0,
              nESA: 0,
              naESA: 0,
              yZON: 0,
              nZON: 0,
              naZON: 0,
              ySRA: 0,
              nSRA: 0,
              naSRA: 0,
              compliant: 0,
              nonCompliant: 0
            });
            this.getReportCounts(i, tempIndex);
          }
          this.lenderRankingTable[tempIndex].y = this.lenderRankingTable[tempIndex].yPCA + this.lenderRankingTable[tempIndex].yESA + this.lenderRankingTable[tempIndex].yZON + this.lenderRankingTable[tempIndex].ySRA;
          this.lenderRankingTable[tempIndex].yn = this.lenderRankingTable[tempIndex].yPCA + this.lenderRankingTable[tempIndex].yESA + this.lenderRankingTable[tempIndex].yZON + this.lenderRankingTable[tempIndex].ySRA +
            this.lenderRankingTable[tempIndex].nPCA + this.lenderRankingTable[tempIndex].nESA + this.lenderRankingTable[tempIndex].nZON + this.lenderRankingTable[tempIndex].nSRA;
          this.lenderRankingTable[tempIndex].complianceRate = (this.lenderRankingTable[tempIndex].compliant / (this.lenderRankingTable[tempIndex].compliant + this.lenderRankingTable[tempIndex].nonCompliant));
          this.lenderRankingTable[tempIndex].submissionRate = (this.lenderRankingTable[tempIndex].y / this.lenderRankingTable[tempIndex].yn);
        }
      }
    } else {
      this.lenderRankingTable = [];
    }

    for (let i = 0; i < this.lenderRankingTable.length; i++) {
      this.ySumPCA = this.ySumPCA + this.lenderRankingTable[i].yPCA;
      this.nSumPCA = this.nSumPCA + this.lenderRankingTable[i].nPCA;
      this.naSumPCA = this.naSumPCA + this.lenderRankingTable[i].naPCA;
      this.ySumESA = this.ySumESA + this.lenderRankingTable[i].yESA;
      this.nSumESA = this.nSumESA + this.lenderRankingTable[i].nESA;
      this.naSumESA = this.naSumESA + this.lenderRankingTable[i].naESA;
      this.ySumZON = this.ySumZON + this.lenderRankingTable[i].yZON;
      this.nSumZON = this.nSumZON + this.lenderRankingTable[i].nZON;
      this.naSumZON = this.naSumZON + this.lenderRankingTable[i].naZON;
      this.ySumSRA = this.ySumSRA + this.lenderRankingTable[i].ySRA;
      this.nSumSRA = this.nSumSRA + this.lenderRankingTable[i].nSRA;
      this.naSumSRA = this.naSumSRA + this.lenderRankingTable[i].naSRA;
      this.compliant = this.compliant + this.lenderRankingTable[i].compliant;
      this.nonCompliant = this.nonCompliant + this.lenderRankingTable[i].nonCompliant;
    }

    //get Y
    this.totalY = this.ySumPCA + this.ySumESA + this.ySumZON + this.ySumSRA;

    //get Y+N
    this.totalYN = this.ySumPCA + this.ySumESA + this.ySumZON + this.ySumSRA +
      this.nSumPCA + this.nSumESA + this.nSumZON + this.nSumSRA;

    //get Compliance Rate
    if ((this.compliant + this.nonCompliant) != 0)
      this.complianceRateLenderRanking = (this.compliant / (this.compliant + this.nonCompliant));
    else
      this.complianceRateLenderRanking = 0;

    //get Submission Rate
    if (this.totalYN != 0)
      this.submissionRateLenderRanking = (this.totalY / this.totalYN);
    else
      this.submissionRateLenderRanking = 0;

    //default sort lender ranking table
    this.lenderRankingTableDisplay = _.orderBy(this.lenderRankingTable, ['complianceRate'], ['desc']);
  }

  public getReportCounts(i, tempIndex) {
    //PCA
    if (this.lenderRankingTabDetails.acqnDashboardDetails[i].pca == 'Y') {
      this.lenderRankingTable[tempIndex].yPCA++;
    } else if (this.lenderRankingTabDetails.acqnDashboardDetails[i].pca == 'N') {
      this.lenderRankingTable[tempIndex].nPCA++;
    } else if (this.lenderRankingTabDetails.acqnDashboardDetails[i].pca == 'N/A') {
      this.lenderRankingTable[tempIndex].naPCA++;
    }
    //ESA
    if (this.lenderRankingTabDetails.acqnDashboardDetails[i].esa == 'Y') {
      this.lenderRankingTable[tempIndex].yESA++;
    } else if (this.lenderRankingTabDetails.acqnDashboardDetails[i].esa == 'N') {
      this.lenderRankingTable[tempIndex].nESA++;
    } else if (this.lenderRankingTabDetails.acqnDashboardDetails[i].esa == 'N/A') {
      this.lenderRankingTable[tempIndex].naESA++;
    }
    //ZON
    if (this.lenderRankingTabDetails.acqnDashboardDetails[i].zon == 'Y') {
      this.lenderRankingTable[tempIndex].yZON++;
    } else if (this.lenderRankingTabDetails.acqnDashboardDetails[i].zon == 'N') {
      this.lenderRankingTable[tempIndex].nZON++;
    } else if (this.lenderRankingTabDetails.acqnDashboardDetails[i].zon == 'N/A') {
      this.lenderRankingTable[tempIndex].naZON++;
    }
    //ZON
    if (this.lenderRankingTabDetails.acqnDashboardDetails[i].sra == 'Y') {
      this.lenderRankingTable[tempIndex].ySRA++;
    } else if (this.lenderRankingTabDetails.acqnDashboardDetails[i].sra == 'N') {
      this.lenderRankingTable[tempIndex].nSRA++;
    } else if (this.lenderRankingTabDetails.acqnDashboardDetails[i].sra == 'N/A') {
      this.lenderRankingTable[tempIndex].naSRA++;
    }
    //Compliance
    switch (this.lenderRankingTabDetails.acqnDashboardDetails[i].complianceRate.toLowerCase()) {
      case "compliant":
        this.lenderRankingTable[tempIndex].compliant++;
        break;
      case "non-compliant":
        this.lenderRankingTable[tempIndex].nonCompliant++;
        break;
      default:
        break;
    }
  }

  // Do a callback and refresh the acqnDetails
  public onRefreshAcqnDetails() {
    // Get start and end date and do a callback to get a new list
    let startDate = this.selectedStartPeriod;
    let endDate = this.selectedEndPeriod;
    let isLenderRanking: boolean = true;

    this.refreshAcqnDetails.emit({ startDate, endDate, isLenderRanking });
  }

  onGetEndPeriodDates() {
    // Get start and end date and do a callback to get a new list
    let startDate: string = this.selectedStartPeriod;
    let endDate: string = this.selectedEndPeriod;
    let isLenderRanking: boolean = true;
    let onload: boolean = false;

    this.getEndPeriodDates.emit({ startDate, endDate, isLenderRanking, onload });
  }
}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EndpointsService } from "../../services/endpoints.service";
import { DashboardService } from "../../services/dashboard.service";
import { OperatingStatementService } from "../../services/operating-statement.service";
import { ApiService } from "../../services/api.service";
import { SellerServiceNumberService } from "../../services/sellerservicernumber.service";
import { SellerServicerNumber } from "../../model/SellerServicerNumber";
import { Utils } from "../../shared/utils";
import { AppConstants } from "../../shared/app.constants";
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import { Router } from "@angular/router";
import { SellerServicerData } from "../../model/SellerServicerData";
import { UserDetails } from "../../model/UserDetails";
import { DashboardDetails, OperatingStatementDetails, Properties } from "../../model/OperatingStatementDetails";
import { OSSponsorPropertyList } from "../../model/OSSponsorPropertyList";
import { LaunchDarklyUtil } from '../../shared/launch-darkly-util';

@Component({
  selector: 'app-os-dashboard',
  templateUrl: './os-dashboard.component.html',
  styleUrls: ['./os-dashboard.component.css']
})
export class OS_DashboardComponent implements OnInit {

  startPeriodSelectionText: string;
  endPeriodSelectionText: string;
  propertySelectionText: string;
  sellerServicerNumber: SellerServicerNumber;
  userDetails: UserDetails;
  sellerServicerDataArray: SellerServicerData[] = [];

  sellerServiceNumberArray: any[];
  lenderName: string;

  initialProperties: Properties[] = [];
  filteredProperties: Properties[] = [];
  initialDashboardDetails: DashboardDetails[] = [];
  totalPropertiesCount: number;
  propertiesReportedCount: number;
  propertiesNotReportedCount: number;
  totalSponsorsCount: number;
  sponsorsRegisteredCount: number;
  sponsorsNotRegisteredCount: number;

  selectStartPeriod: any[] = [];
  selectEndPeriod: any[] = [];
  selectedStartPeriod: string = "";
  selectedEndPeriod: string = "";

  selectProperty: any[] = [];
  selectedProperty: bigint[] = [];
  selectedPropertyList: bigint[] = [];

  monthNames: any[];
  internalPortalTableCols: any[];
  lenderPortalTableCols: any[];
  navigationLinks: any[];

  globalFilterBinding: string;

  public alertFeedback: any = [];
  isLoading: boolean = false;

  isInternalPortal: boolean;
  priorMonthFlag: boolean = false;
  disableDownload: boolean;

  overviewDropdownMenu: any[] = [];
  selectedOverviewPeriod: string = "";
  selectedOverviewPeriodAPIFormat: string = "";
  currentDateString: string = "";

  operatingStmtsSponsorPropertyList: OSSponsorPropertyList[] = [];
  operatingStmtsSponsorPropertyListDisplay: any[] = [];
  sponsorSelected: boolean = false;
  sponsorPropertyListCols: any[];
  sponsorSelectedName: string = "";

  systemNotificationFlagValue = {};
  showSystemNotification = false;

  constructor(private httpClient: HttpClient, private endpointsService: EndpointsService,
    private dashboardService: DashboardService, private operatingStatementService: OperatingStatementService,
    private apiService: ApiService, private sellerServiceNumberService: SellerServiceNumberService,
    private utils: Utils, private router: Router,
    private launchDarkly: LaunchDarklyUtil) {
    let date = new Date();
    this.selectedOverviewPeriodAPIFormat = (date.getMonth() + 1) + "/" + date.getFullYear();
    this.getSellerServicerNumber();
  }

  ngOnInit() {
    this.initLaunchDarkly();
    
    this.startPeriodSelectionText = AppConstants.START_PERIOD_SELECTION_TEXT;
    this.endPeriodSelectionText = AppConstants.END_PERIOD_SELECTION_TEXT;
    this.propertySelectionText = AppConstants.PROPERTY_SELECTION_TEXT;

    this.monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];

    this.getDropDownDates();
    this.getStartPeriodDates();
    this.getEndPeriodDates();

    this.navigationLinks = [
      {
        href: '/',
        label: 'Home'
      },
      {
        href: '#',
        label: 'Operating Statement'
      }
    ];

    this.internalPortalTableCols = [
      { field: 'name', header: 'Lender Name' },
      { field: 'sponsorCnt', header: 'Number Of Sponsors' },
      { field: 'propertiesCnt', header: 'Number Of Properties' },
      { field: 'propertiesReported', header: 'Operating Statements Reported' },
      { field: 'propertiesNotReported', header: 'Operating Statements Not Reported' }
    ];

    this.lenderPortalTableCols = [
      { field: 'name', header: 'Sponsor Name' },
      { field: 'propertiesCnt', header: 'Number Of Properties' },
      { field: 'propertiesReported', header: 'Operating Statements Reported' },
      { field: 'propertiesNotReported', header: 'Operating Statements Not Reported' }
    ];

    this.sponsorPropertyListCols = [
      { field: 'propNme', header: 'Property Name', width: '30%', tooltip: '' },
      { field: 'grsPotlRntAmt', header: 'GPR', width: '18%', tooltip: 'Gross Potential Rent' },
      { field: 'effectiveGrsIncome', header: 'EGI', width: '17%', tooltip: 'Effective Gross Income' },
      { field: 'totalOperatingExpenses', header: 'TOE', width: '17%', tooltip: 'Total Operating Expenses' },
      { field: 'netOperatingIncome', header: 'NOI', width: '18%', tooltip: 'Net Operating Income' }
    ];
  }

  getSellerServicerNumber() {
    //Get SSN from IDP
    if (this.apiService.getUserDetails().isExternalUser) {
      this.isInternalPortal = false;
      this.sellerServiceNumberService.getSellerServiceNumber(this.apiService.getUserDetails().uid).subscribe
        ((sellerServicerData: SellerServicerData[]) => {
          this.sellerServicerDataArray = sellerServicerData;
          this.sellerServicerNumber = this.apiService.getSellerServicerNumber();

          //Place all SSN's in a string
          if (this.sellerServicerDataArray[0].sellerServicerNum) {
            let tmpSSNArray = [];
            for (let i = 0; i < this.sellerServicerDataArray.length; i++) {
              if (this.sellerServicerDataArray[i].sellerServicerNum && !tmpSSNArray.includes(this.sellerServicerDataArray[i].sellerServicerNum)) {
                tmpSSNArray.push(this.sellerServicerDataArray[i].sellerServicerNum)
              }
              this.sellerServicerNumber.sellerServicerNo = tmpSSNArray.join(',');
            }
          } else {
            this.sellerServicerNumber.sellerServicerNo = '';
          }

          this.apiService.saveSellerServicerNumber(this.sellerServicerNumber);
          this.userDetails = this.apiService.getUserDetails();

          this.sellerServiceNumberArray = _.split(this.apiService.getSellerServicerNumber().sellerServicerNo, ',');
          this.lenderName = this.apiService.getUserDetails().subscriberName;
        },
          err => {
            // We don't want customer to continue if there is an error getting SSNs.
            this.router.navigate(['error']);
          }, () => {
            this.priorMonthFlag = false;
            this.getLenderPortalValues();
            this.getOperatingStmtsSponsorPropertyList();
          });
    } else if (this.apiService.getUserDetails().isInternalUser) {
      this.isInternalPortal = true;
      this.showAlertFeedback('info', 'Logged in as Internal User, NO SSN loaded.');
      this.apiService.saveCorporateFamily('');
      this.sellerServiceNumberArray = [];
      this.lenderName = "Fannie Mae";
      this.priorMonthFlag = false;
      this.getInternalPortalValues();
    } else {
      this.showAlertFeedback('error', 'Logged in as Unknown User.');
      this.apiService.saveCorporateFamily('');
      this.sellerServiceNumberArray = [];
    }
  }

  //Get Operating Statement Details for Internal User
  getInternalPortalValues() {
    this.isLoading = !this.isLoading;
    this.operatingStatementService.getDashboardDetails(null, null, this.selectedOverviewPeriodAPIFormat, this.priorMonthFlag, true).subscribe(
      (res: OperatingStatementDetails) => {
        this.initialDashboardDetails = res.dashboardDetails;
        if (!res.totalSponsors) {
          this.totalSponsorsCount = 0;
          this.sponsorsNotRegisteredCount = 0;
        } else {
          this.totalSponsorsCount = res.totalSponsors;
          this.sponsorsNotRegisteredCount = res.sponsorsNotRegistered;
        }
        this.sponsorsRegisteredCount = res.sponsorsRegistered;
      }, (err: any) => {
        this.showAlertFeedback("error", AppConstants.GENERAL_ERROR_MSG_SERVER);
        this.isLoading = !this.isLoading;
      }, () => {
        this.sortSponsorTable();
        this.calculatePropertyCounts();
        this.isLoading = !this.isLoading;
      });
  }

  //Get Operating Statement Details for External User
  getLenderPortalValues() {
    this.isLoading = !this.isLoading;
    this.operatingStatementService.getDashboardDetails(this.sellerServiceNumberArray, this.lenderName, this.selectedOverviewPeriodAPIFormat, this.priorMonthFlag, false).subscribe(
      (res: OperatingStatementDetails) => {
        this.initialProperties = res.properties;
        this.initialDashboardDetails = res.dashboardDetails;
        if (!res.totalSponsors) {
          this.totalSponsorsCount = 0;
          this.sponsorsNotRegisteredCount = 0;
        } else {
          this.totalSponsorsCount = res.totalSponsors;
          this.sponsorsNotRegisteredCount = res.sponsorsNotRegistered;
        }
        this.sponsorsRegisteredCount = res.sponsorsRegistered;
        if (this.initialProperties.length == 0)
          this.showAlertFeedback("info", AppConstants.NO_PROPERTIES_MAPPED_TO_USER_OS);
      }, (err: any) => {
        this.showAlertFeedback("error", AppConstants.GENERAL_ERROR_MSG_SERVER);
        this.isLoading = !this.isLoading;
      }, () => {
        this.sortSponsorTable();
        this.calculatePropertyCounts();
        this.isLoading = !this.isLoading;
      });
  }

  getOperatingStmtsSponsorPropertyList() {
    this.isLoading = !this.isLoading;
    this.operatingStatementService.operatingStmtsSponsorPropertyList(this.sellerServiceNumberArray,
      this.apiService.getUserDetails().isInternalUser, this.lenderName, this.selectedOverviewPeriodAPIFormat).subscribe(
        (res: OSSponsorPropertyList[]) => {
          this.operatingStmtsSponsorPropertyList = res;
        }, (err: any) => {
          this.showAlertFeedback("error", AppConstants.GENERAL_ERROR_MSG_SERVER);
          this.isLoading = !this.isLoading;
        }, () => {
          for (let i = 0; i < this.operatingStmtsSponsorPropertyList.length; i++) {
            this.operatingStmtsSponsorPropertyList[i].sponsor = this.operatingStmtsSponsorPropertyList[i].sponsor.toUpperCase();
          }
          this.isLoading = !this.isLoading;
        });
  }

  sortSponsorTable() {
    this.initialDashboardDetails = _.reverse(_.sortBy(this.initialDashboardDetails, ['propertiesReported', 'propertiesCnt']));
    let res = this.initialDashboardDetails.slice(), len = res.length;
    for (let i = 0; i < len; i++) {
      if (res[i].name == "Unassigned") {
        res.push(res.splice(i, 1)[0]);
        i--;
        len--;
      }
    }
    this.initialDashboardDetails = res;
  }

  calculatePropertyCounts() {
    this.totalPropertiesCount = 0;
    this.propertiesReportedCount = 0;
    this.propertiesNotReportedCount = 0;
    for (let i = 0; i < this.initialDashboardDetails.length; i++) {
      this.totalPropertiesCount = this.totalPropertiesCount + this.initialDashboardDetails[i].propertiesCnt;
      this.propertiesReportedCount = this.propertiesReportedCount + this.initialDashboardDetails[i].propertiesReported;
      this.propertiesNotReportedCount = this.propertiesNotReportedCount + this.initialDashboardDetails[i].propertiesNotReported;
    }
  }

  getDropDownDates() {
    let date = new Date();
    let months = [];
    let currentDate = new Date();
    this.currentDateString = (currentDate.getMonth() + 1) + '/' + currentDate.getFullYear();
    for (let i = 0; i < 12; i++) {
      if ((this.monthNames[date.getMonth()] + ' ' + date.getFullYear()) == AppConstants.START_PERIOD_OS) {
        months.push(this.monthNames[date.getMonth()] + ' ' + date.getFullYear());
        break;
      } else {
        months.push(this.monthNames[date.getMonth()] + ' ' + date.getFullYear());
        date.setMonth(date.getMonth() - 1);
      }
    }
    for (let i = 0; i < months.length; i++) {
      this.overviewDropdownMenu.push({ label: months[i], value: months[i] });
    }
    this.selectedOverviewPeriod = this.monthNames[currentDate.getMonth()] + " " + currentDate.getFullYear();
  }

  getStartPeriodDates() {
    this.selectStartPeriod = [];

    let date = new Date();
    let months = [];
    for (let i = 0; i < 12; i++) {
      if ((this.monthNames[date.getMonth()] + ' ' + date.getFullYear()) == AppConstants.START_PERIOD_OS) {
        months.push(this.monthNames[date.getMonth()] + ' ' + date.getFullYear());
        break;
      } else {
        months.push(this.monthNames[date.getMonth()] + ' ' + date.getFullYear());
        date.setMonth(date.getMonth() - 1);
      }
    }
    this.selectStartPeriod.push({ label: AppConstants.START_PERIOD_SELECTION_TEXT, value: "" });
    for (let i = 0; i < months.length; i++) {
      this.selectStartPeriod.push({ label: months[i], value: months[i] });
    }
  }

  filterPropertiesByPeriodDates() {
    //get first day of the month for selected start date
    let selectedStartDate = new Date(this.selectedStartPeriod);
    let startDate = new Date(selectedStartDate.getFullYear(), selectedStartDate.getMonth(), 1);

    //get last day of the month for selected end date
    let selectedEndDate = new Date(this.selectedEndPeriod);
    let endDate = new Date(selectedEndDate.getFullYear(), selectedEndDate.getMonth() + 1, 0);

    //filter properties by date
    this.filteredProperties = this.initialProperties.filter(property => this.utils.formatDate(new Date(property.osStmtDate), 'yyyy-MM-dd')
      >= this.utils.formatDate(startDate, 'yyyy-MM-dd')
      && this.utils.formatDate(new Date(property.osStmtDate), 'yyyy-MM-dd') <= this.utils.formatDate(endDate, 'yyyy-MM-dd'));

    //remove duplicate properties
    const propertyIds = _.uniq(this.filteredProperties.map(o => o.propertyId));
    this.getProperties(propertyIds);
  }

  getEndPeriodDates() {
    this.selectEndPeriod = [];
    this.alertFeedback = [];

    if (this.selectedEndPeriod != "")
      this.filterPropertiesByPeriodDates();

    let date = new Date();
    let months = [];
    let startDate = new Date();
    if (this.selectedStartPeriod != "")
      startDate = new Date(this.selectedStartPeriod);
    else
      startDate = new Date(AppConstants.START_PERIOD_OS);
    for (let i = 0; i < 12; i++) {
      if ((this.monthNames[date.getMonth()] + ' ' + date.getFullYear()) == (this.monthNames[startDate.getMonth()] + ' ' + startDate.getFullYear())) {
        months.push(this.monthNames[date.getMonth()] + ' ' + date.getFullYear());
        break;
      } else {
        months.push(this.monthNames[date.getMonth()] + ' ' + date.getFullYear());
        date.setMonth(date.getMonth() - 1);
      }
    }
    this.selectEndPeriod.push({ label: AppConstants.END_PERIOD_SELECTION_TEXT, value: "" });
    for (let i = 0; i < months.length; i++) {
      this.selectEndPeriod.push({ label: months[i], value: months[i] });
    }
  }

  getProperties(propertyIds) {
    this.selectProperty = [];
    for (let j = 0; j < propertyIds.length; j++) {
      for (let i = 0; i < this.filteredProperties.length; i++) {
        if (propertyIds[j] === this.filteredProperties[i].propertyId) {
          this.selectProperty.push({
            label: this.filteredProperties[i].propertyName,
            value: this.filteredProperties[i].propertyId,
            disabled: !this.filteredProperties[i].downloadFlag
          });
          break;
        }
      }
    }
    //sort properties
    if (this.selectProperty.length >= 1) {
      this.selectProperty = _.sortBy(this.selectProperty, ['label']);
    }
    //disable alerts and download if no properties for selected period
    //disable download if only disabled properties for selected date period
    if (this.selectProperty.length >= 1 &&
      (_.filter(this.selectProperty, ['disabled', true]).length != this.selectProperty.length)) {
      this.disableDownload = false;
      this.alertFeedback = [];
    } else {
      this.disableDownload = true;
      this.showAlertFeedback('warning', AppConstants.NO_DATA_FOR_SELECTION);
    }
  }

  //API File Download
  streamExcel() {
    //Clear alert messages
    this.alertFeedback = [];

    //Get User Selected Parameters
    let startDate = new Date(this.selectedStartPeriod);
    let endDate = new Date(this.selectedEndPeriod);

    let formattedStartDate = startDate.getMonth() + 1 + '/' + startDate.getFullYear();
    let formattedEndDate = endDate.getMonth() + 1 + '/' + endDate.getFullYear();

    //Get values for downloaded report file name
    let printStartDate = startDate.getFullYear() + '' + ('0' + (startDate.getMonth() + 1)).slice(-2);
    let printEndDate = endDate.getFullYear() + '' + ('0' + (endDate.getMonth() + 1)).slice(-2);
    let currentDate = new Date();
    let printCurrentDate = (currentDate.getFullYear() + ('0' + (currentDate.getMonth() + 1)).slice(-2) + '' + (('0' + currentDate.getDate()).slice(-2)));
    let printProperty = "";
    if (this.selectedPropertyList.length == 1)
      printProperty = this.initialProperties[_.findIndex(this.initialProperties, ['propertyId',
        this.selectedPropertyList[0]])].propertyName.replace(" ", "").slice(0, 8);
    else if (this.selectedPropertyList.length == 0)
      printProperty = "AllProps";
    else
      printProperty = "MultProp";

    let filename = 'OS_' + printStartDate + '-' + printEndDate + '_' + printCurrentDate + '_' + printProperty + '.xlsx';

    this.isLoading = !this.isLoading;
    this.operatingStatementService.generateExcel(this.sellerServiceNumberArray, this.lenderName, formattedStartDate, formattedEndDate, this.selectedPropertyList).subscribe(
      (res: any) => {
        if (res) {
          const blob = this.operatingStatementService.base64toBlob(res.body, '');
          FileSaver.saveAs(blob, filename);
        }
      }, (err: any) => {
        if (err.status === 413) {
          this.showAlertFeedback('warning', AppConstants.TOO_MANY_PROPERTIES_RESULTED_FOR_SELECTION_OS);
          this.isLoading = !this.isLoading;
        } else {
          this.showAlertFeedback('warning', AppConstants.NO_DATA_FOR_SELECTION);
          this.isLoading = !this.isLoading;
        }
      }, () => {
        this.isLoading = !this.isLoading;
      });
  }

  showSponsorPropertyList(rowData) {
    //filter values to display on table
    this.operatingStmtsSponsorPropertyListDisplay = [];
    this.operatingStmtsSponsorPropertyListDisplay = _.filter(this.operatingStmtsSponsorPropertyList, ['sponsor', rowData.name.toUpperCase()]);
    if (this.operatingStmtsSponsorPropertyListDisplay.length > 0) {
      //show property list table on right
      this.sponsorSelected = true;
      this.sponsorSelectedName = rowData.name.toUpperCase();
    } else {
      this.hideSponsorPropertyList();
    }

  }

  hideSponsorPropertyList() {
    this.sponsorSelected = false;
    this.sponsorSelectedName = "";
    this.operatingStmtsSponsorPropertyListDisplay = [];
  }

  saveSelectedOverviewPeriod(event) {
    let selectedDatePeriod = new Date(event.value);
    this.selectedOverviewPeriod = event.value;
    this.selectedOverviewPeriodAPIFormat = (selectedDatePeriod.getMonth() + 1) + "/" + selectedDatePeriod.getFullYear();
    this.hideSponsorPropertyList();
    if (!this.isInternalPortal) {
      if (this.currentDateString != this.selectedOverviewPeriodAPIFormat) {
        this.getLenderPortalValues();
        this.priorMonthFlag = true;
        this.getLenderPortalValues();
        this.getOperatingStmtsSponsorPropertyList();
      } else {
        this.priorMonthFlag = false;
        this.getLenderPortalValues();
        this.getOperatingStmtsSponsorPropertyList()
      }
    } else {
      if (this.currentDateString != this.selectedOverviewPeriodAPIFormat) {
        this.priorMonthFlag = true;
        this.getInternalPortalValues();
      } else {
        this.priorMonthFlag = false;
        this.getInternalPortalValues();
      }
    }
  }

  saveSelectedProperty(event) {
    if (event.isAllChecked)
      this.selectedPropertyList = [];
    else
      this.selectedPropertyList = this.selectedProperty;
  }

  showAlertFeedback(type: string, message: string) {
    this.alertFeedback = [];
    this.alertFeedback.push({ type: type, message: message });
  }

  navigateToLandingPage() {
    this.router.navigate(['/'], { skipLocationChange: true });
  }

  showInternalPortal() {
    this.isInternalPortal = true;
    this.lenderName = "Fannie Mae";
    this.sellerServiceNumberArray = null;
    this.showPage1Navigation();
    this.getInternalPortalValues();
  }

  showLenderPortal(rowData) {
    this.isInternalPortal = false;
    this.lenderName = rowData.name;
    this.sellerServiceNumberArray = null;
    this.showPage2Navigation();
    this.getStartPeriodDates();
    this.getEndPeriodDates();
    this.getLenderPortalValues();
    this.getOperatingStmtsSponsorPropertyList();
  }

  resetDownloadTool() {
    this.selectStartPeriod = [];
    this.selectEndPeriod = [];
    this.selectedStartPeriod = "";
    this.selectedEndPeriod = "";
    this.selectProperty = [];
    this.selectedProperty = null;
  }

  showPage1Navigation() {
    this.navigationLinks = [
      {
        href: '/',
        label: 'Home'
      },
      {
        href: '#',
        label: 'Operating Statement'
      }
    ];
  }

  showPage2Navigation() {
    this.navigationLinks = [
      {
        href: '/',
        label: 'Home'
      },
      {
        href: '#',
        label: 'Operating Statement'
      },
      {
        href: '#',
        label: 'Lender Portal'
      }
    ];
  }

  navigate(link) {
    if (link.label == "Operating Statement") {
      this.showInternalPortal();
      this.hideSponsorPropertyList();
      this.resetDownloadTool();
    }
  }

  initLaunchDarkly() {
    this.launchDarkly.getLaunchDarklyNotification(this, this.launchDarklyCallback)
  }

  launchDarklyCallback(systemNotificationFlagValue, caller) {
    caller.systemNotificationFlagValue = systemNotificationFlagValue;
    console.log('LaunchDarkly flag systemNotificationFlagValue = ', caller.systemNotificationFlagValue);
    if (Object.keys(caller.systemNotificationFlagValue).length > 0) {
      caller.showSystemNotification = true;
    }  
  }
}

/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorComponent} from './component/error/error.component';
import {DD_DashboardComponent} from "./due-diligence/dashboard/dd-dashboard.component";
import {RR_DashboardComponent} from './rent-roll/dashboard/rr-dashboard.component';
import {HomeComponent} from './home/home.component';
import {LoginGuard} from "./login.guard";
import {UnAuthorizedComponent} from './component/unauthorized/unauthorized.component';
import {OS_DashboardComponent} from "./operating-statements/dashboard/os-dashboard.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'rr_dashboard',
    component: RR_DashboardComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'dd_dashboard',
    component: DD_DashboardComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'os_dashboard',
    component: OS_DashboardComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'un-authorized',
    component: UnAuthorizedComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

<fm-dialog id="contact-us-display" header="Contact Us" [alignLeft]="true" [(visible)]="contactUsDisplay" modal="modal"
           [responsive]="true" [dismissableMask]="true" [width]="screenWidth" [height]="screenHeight">
  <br>
  <b>Phone Number: </b>{{suppportPhoneNumber}}
  <br><br>
  <b>Email: </b>{{supportEmail}}
  <br><br>
  <a href="{{supportWebsite}}" target="_blank"><u>Click here for further help</u></a>
  <br><br>
</fm-dialog>
<fm-footer>
  <div id="copy-right">
    <img src="assets/images/fm-logo.png">
    {{copyrightMessage}}
  </div>
  <div id="contact-us">
    <a id="contact-us-button" class="contact-us-button" [routerLink]="[]" (click)="toggleContactUs()"><u>Contact Us</u></a>
  </div>
</fm-footer>

/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {catchError, tap} from 'rxjs/operators';
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import {environment, logoutEndPoints} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SellerServicerNumber} from '../model/SellerServicerNumber';
import {Utils} from '../shared/utils';
import {UserDetails} from "../model/UserDetails";

declare var window: any;
declare var gtag: any;

@Injectable()
export class ApiService implements HttpInterceptor {

  /** To avoid multiple logout(s) in case of 401 */
  public handledUnauthorized = false;
  /** Path local storage to read dummy json */
  private localUrl = '../../../assets/data/';

  /** Customer profile to store SSN */
  private sellerServicerNumber: SellerServicerNumber;
  /** customer information subscription */
  public sellerServicerNumberSub = new Subject<SellerServicerNumber>();

  /** User Details from /getUserDetails */
  private userDetails: UserDetails;
  /** userDetails subscription */
  public userDetailsSub = new Subject<UserDetails>();

  constructor(public http: HttpClient, private router: Router, private utils: Utils) {
  }

  /** Method to get data from local storage */
  public getLocal(apikey: string): Observable<any> {
    return this.get(this.localUrl + apikey + '.json')
      .pipe(catchError(this.handleError.bind(this)));
  }

  /** Makes request to gateway, body and custom headers are optional; This method will take care of sending
   *  required headers;
   *
   *  skipDefaultErrorHandling : if 'true' will not display global error page, will let caller handle error
   *  rawBody: To send empty body (stringfy will add curely braces)
   */
  public sendRequest(apiUrl: string, body?: any, headers?: { [key: string]: string }, skipDefaultErrorHandling?: boolean,
                     rawBody?: boolean): Observable<any> {

    const _body = !rawBody ? JSON.stringify(body) : body;

    return this.post(apiUrl, _body, (!!headers && !!headers.responseType && headers) || this.setHeaders(headers))
      .pipe(catchError(skipDefaultErrorHandling ? this.skipDefaultErrors.bind(this) : this.handleError.bind(this)));
  }

  /** Makes request to gateway, custom headers are optional; This method will take care of sending
   *  required headers with GET method;
   */
  public sendGetRequest(apiUrl: string, headers?: { [key: string]: string }, skipDefaultErrorHandling?: boolean): Observable<any> {

    return this.get(apiUrl, this.setHeaders(headers))
      .pipe(catchError(skipDefaultErrorHandling ? this.skipDefaultErrors.bind(this) : this.handleError.bind(this)));
  }

  /**
   *  To handle requests where we don't display global error page in case of 401
   *  but will display error in case of other errors
   */
  public sendGetRequestWithoutDefaultAuthError(apiUrl: string, headers?: { [key: string]: string }): Observable<any> {
    return this.get(apiUrl, this.setHeaders(headers))
      .pipe(catchError(this.skipDefaultAuthError.bind(this)));
  }

  /** Method to add custom headers if any*/
  private setHeaders(extraHeaders?: { [key: string]: string }): any {
    let reqHeaders = new HttpHeaders();

    if (extraHeaders) {
      for (const key of Object.keys(extraHeaders)) {
        reqHeaders = reqHeaders.append(key, extraHeaders[key]);
      }
    }
    return {headers: reqHeaders};
  }

  /** To handle HTML response on success */
  public handleSuccess(res: any) {
    const errHandler: any = res;
    try {
      return res;
    } catch (err) {
      // Returning raw body if response is HTML
      return errHandler._body || {};
    }
  }

  /** To handle errors from back end requests */
  public handleError(error: any) {
    this.validateErrors(error.status);
  }

  /** Handles errors  */
  public skipDefaultAuthError(error: any) {
    this.validateReturnErrors(error.status);
    return throwError(error);
  }

  public skipDefaultErrors(error: any) {
    return throwError(error);
  }

  public getRedirectUrl() {
    const hostname = window.location.hostname;

    // Check if host name is self-service
    const endPoint = logoutEndPoints[hostname];
    if (endPoint) {
      return endPoint.logoutUrl;
    }

  }

  public get(url: string, options?: any): Observable<any> {
    return this.http.get(url, options);
  }


  public post(url: string, body: string, options?: any): Observable<any> {
    return this.http.post(url, body, options);
  }

  public put(url: string, body: string, options?: any): Observable<any> {
    return this.http.put(url, body);
  }

  public delete(url: string, options?: any): Observable<any> {
    return this.http.delete(url);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.getDefaultHeaders(request);

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const sessionid: string = event.headers.get('x-fnma-sessionid');
          if (sessionid && sessionid.length > 0) {
            window.localStorage.setItem('sessionId', sessionid);
          }
        }
      }
      , (error: any) => {
        this.validateErrors(error.status);
      }));
  }


  public getDefaultHeaders(request): HttpRequest<any> {
    const sessionId: any = window.localStorage.getItem('sessionId') || '';
    if (sessionId && sessionId.length > 0) {
      const requesHeaders = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'x-fnma-sessionid': sessionId,
          'x-fnma-channel': 'web',
          'x-fnma-sub-channel': this.getBrowserInformation(),
          'x-fnma-app-code': 'MFDSTDN',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Headers': '*'
        },
      });
      return requesHeaders;
    }

    // Without session ID (non-UPF calls)
    const requestWithHeaders = request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'x-fnma-channel': 'web',
        'x-fnma-sub-channel': this.getBrowserInformation(),
        'x-fnma-app-code': 'MFDSTDN',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Headers': '*'
      },
    });

    return requestWithHeaders;
  }

  public validateErrors(errorStatus) {
    if (errorStatus === 401) {
      this.utils.login();
    }
  }

  /** Special case for FM Connect since they are not handling no data case */
  public validateReturnErrors(errorStatus) {
    if (errorStatus === 401) {
      this.utils.login();
    }
  }

  /** Http override methods end */
  public getBrowserInformation(): string {
    /** get browser details */
    const nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = '' + parseFloat(navigator.appVersion);
    let majorVersion;
    let nameOffset;
    let verOffset;
    let ix;

    // In Opera 15+, the true version is after 'OPR/'
    if ((verOffset = nAgt.indexOf('OPR/')) !== -1) {
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 4);
    } else if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
      // In older Opera, the true version is after 'Opera' or after 'Version'
      browserName = 'Opera';
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
      // In MSIE, the true version is after 'MSIE' in userAgent
      browserName = 'Microsoft Internet Explorer';
      fullVersion = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
      // In Chrome, the true version is after 'Chrome'
      browserName = 'Edge';
      fullVersion = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
      // In Chrome, the true version is after 'Chrome'
      browserName = 'Chrome';
      fullVersion = nAgt.substring(verOffset + 7);
    } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
      // In Safari, the true version is after 'Safari' or after 'Version'
      browserName = 'Safari';
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
      // In Firefox, the true version is after 'Firefox'
      browserName = 'Firefox';
      fullVersion = nAgt.substring(verOffset + 8);
    } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))) {
      // In most other browsers, 'name/version' is at the end of userAgent
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(';')) !== -1) {
      fullVersion = fullVersion.substring(0, ix);
    }
    if ((ix = fullVersion.indexOf(' ')) !== -1) {
      fullVersion = fullVersion.substring(0, ix);
    }

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = '' + parseFloat(navigator.appVersion);
    }

    return browserName + '-' + fullVersion;
  }

  /** User SSN information */
  public getSellerServicerNumber() {
    if (this.sellerServicerNumber) {
      return this.sellerServicerNumber;
    }
    return this.getEmptyCustomerProfile();
  }

  /** User information */
  public getUserDetails() {
    if (this.userDetails) {
      return this.userDetails;
    }
    return this.getEmptyUserDetailsProfile();
  }

  public updateUserDetails(userDetails) {
    this.userDetails = userDetails;
  }

  /** Returning empty data to avoid crash */
  public getEmptyCustomerProfile() {
    const customerProfile: SellerServicerNumber = {
      sellerServicerNo: ''
    };

    return customerProfile;
  }

  /** Returning empty data to avoid crash */
  public getEmptyUserDetailsProfile() {
    let firstName = '';
    let lastName = '';
    if (!environment.production) {
      firstName = 'John';
      lastName = 'Smith';
    }

    const userDetailsProfile: UserDetails = {
      firstName: firstName,
      lastName: lastName,
      mail: '',
      telephoneNumber: '',
      uid: '',
      isInternalUser: true,
      isExternalUser: null,
      subscriberName: '',
      userType: '',
      corporateFamilyCode: '',
      institutionIdDefault: '',
      primaryKeySubcriberId: '',
      groups: [],
      emailAddress: '',
      parentId: '',
      sellerServicerCoNo: ''
    };

    return userDetailsProfile;
  }

  /** Save all information received in UserDetails */
  public saveUserDetails(userDetails) {
    this.userDetails = {
      firstName: userDetails.userDetails.firstName,
      lastName: userDetails.userDetails.lastName,
      mail: userDetails.userDetails.mail,
      telephoneNumber: userDetails.userDetails.telephoneNumber,
      uid: userDetails.userDetails.uid,
      isInternalUser: this.utils.getBoolean(userDetails.userDetails.isInternalUser),
      isExternalUser: this.utils.getBoolean(userDetails.userDetails.isExternalUser),
      subscriberName: userDetails.userDetails.subscriberName,
      userType: userDetails.userDetails.userType,
      corporateFamilyCode: userDetails.userDetails.corporateFamilyCode,
      institutionIdDefault: userDetails.userDetails.institutionIdDefault,
      primaryKeySubcriberId: userDetails.userDetails.primaryKeySubcriberId,
      groups: userDetails.userDetails.groups,      
      emailAddress: userDetails.userDetails.emailAddress,
      parentId: userDetails.userDetails.parentId,
      sellerServicerCoNo: userDetails.userDetails.sellerServicerCoNo
    };

    if (this.userDetails) {
      this.userDetailsSub.next(this.userDetails);
    }
  }

  /** Save SSN information */
  public saveSellerServicerNumber(sellerServicerNumber) {
    this.sellerServicerNumber = {
      sellerServicerNo: sellerServicerNumber.sellerServicerNo
    };

    if (this.sellerServicerNumber) {
      this.sellerServicerNumberSub.next(this.sellerServicerNumber);
    }
  }

  public saveCorporateFamily(corporateFamily) {
    if (this.userDetails) {
      this.userDetails.corporateFamilyCode = corporateFamily;
      this.userDetailsSub.next(this.userDetails);
    }
  }
}

/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {Component, HostListener, OnInit} from "@angular/core";
import {AppConstants} from "../../shared/app.constants";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  contactUsDisplay;
  supportEmail: string;
  suppportPhoneNumber: string;
  supportWebsite: string;
  screenHeight: number;
  screenWidth: number;
  copyrightMessage: string;

  constructor() {
  }

  ngOnInit() {
    this.supportEmail = AppConstants.SUPPORT_EMAIL;
    this.suppportPhoneNumber = AppConstants.SUPPORT_PHONE_NUMBER;
    this.supportWebsite = AppConstants.SUPPORT_WEBSITE;

    this.createCopyrightMessage();
  }

  toggleContactUs() {
    this.contactUsDisplay = !this.contactUsDisplay;
  }

  createCopyrightMessage() {
    let date = new Date();
    this.copyrightMessage = date.getFullYear() + " Fannie Mae.  All Rights Reserved.";
  }
}

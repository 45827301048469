<div id="externalUserDashboard" class="dataStandard" *ngIf="!showIssueSummary && !showIssueSummaryPreview">
  <fm-loading-spinner [isLoading]="isLoadingDueDiligence"></fm-loading-spinner>
  <fm-notice [notice]="notice"></fm-notice>
  <fm-alert-feedback [alignLeft]="false" [alert]="alertFeedback"></fm-alert-feedback>

  <br>

  <div class="sub-header">
    <div id="navigation-links">
      <fm-breadcrumb [compressed]="true" [links]="navigationLinks"></fm-breadcrumb>
    </div>
    <div id="lender-name">
      {{lenderName}}
    </div>
  </div>

  <app-user-notification *ngIf="showSystemNotification" [title]="systemNotificationFlagValue.title"
    [message]="systemNotificationFlagValue.message">
  </app-user-notification>
  <br>

  <div id="userInformationTable" class="userInformationTable">
    <fm-table [columns]="cols1" [value]="userInfoArray">
      <ng-template fmTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template fmTemplate="body" let-rowData let-columns="columns">
        <tr align="center">
          <td *ngFor="let col of columns">
            {{rowData[col.field]}}
          </td>
        </tr>
      </ng-template>
    </fm-table>
  </div>

  <div *ngIf="docWayDetailsService.hasDealId">
    <br>
  </div>

  <div id="docwayDetailsTable" class="docwayDetailsTable" *ngIf="docWayDetailsService.hasDealId">
    <fm-table [columns]="cols2" [value]="docWayDetailsService.docwayDetailsArray">
      <ng-template fmTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>
      <ng-template fmTemplate="body" let-rowData let-columns="columns">
        <tr align="center">
          <td *ngFor="let col of columns">
            {{rowData[col.field]}}
          </td>
        </tr>
      </ng-template>
    </fm-table>
  </div>

  <br>
  <br>

  <fm-tabView (onChange)="loadTabData($event)">
    <fm-tabPanel header="Lender Ranking" *ngIf="showTabLenderRanking">
      <app-lender-ranking [acqnDetails]='acqnDetails' [selectStartPeriod]="selectStartPeriod"
        [selectedStartPeriod]="selectedStartPeriod" [selectEndPeriod]="selectEndPeriod"
        [selectedEndPeriod]="selectedEndPeriod" [isInternal]="isInternal"
        (refreshAcqnDetails)="onRefreshAcqnDetails($event)" (getEndPeriodDates)="onGetEndPeriodDates($event)">
      </app-lender-ranking>
    </fm-tabPanel>

    <fm-tabPanel header="Acquisitions" *ngIf="showTabAcquisitions">
      <app-acquisitions [acqnDetails]='acqnDetails' [selectStartPeriod]="selectStartPeriod"
        [selectedStartPeriod]="selectedStartPeriod" [selectEndPeriod]="selectEndPeriod"
        [selectedEndPeriod]="selectedEndPeriod" [isInternal]="isInternal"
        [showAcqnTableEditCheckbox]="showAcqnTableEditCheckbox" (refreshAcqnDetails)="onRefreshAcqnDetails($event)"
        (getEndPeriodDates)="onGetEndPeriodDates($event)"></app-acquisitions>
    </fm-tabPanel>

    <fm-tabPanel header="Data Files" *ngIf="showTabDataFiles" [selected]="dataFilesTabSelected">
      <div id="dataFilesSummary" *ngIf="isInternal == true && isAssetManager != true && isMAUser != true">
        <div id="dataFilesLenderSelection" class="drop-down-selection">
          <b class="drop-down-label">Lender</b>
          <fm-select [options]="dataFilesLenderList" [(ngModel)]="dataFilesSelectedLender"
            (onChange)="filterBasedOnLenderName($event, 'Data Files')" placeholder="All" [filter]="true"
            [showFilter]="true" filterType="include"></fm-select>
        </div>
      </div>

      <div class="subtitle" *ngIf="isAssetManager != true">
        <i>{{tableSubtitle}}</i>
      </div>

      <div id="submittedFilesTable" class="submittedFilesTable">
        <fm-table #dftbl [columns]="!isMAUser ? cols3 : cols3_bpo" [value]="dataFilesDetailsDashboardDisplay"
          sortMode="single" [loadingIcon]="true" [paginator]="true" [rows]="30"
          [rowsPerPageOptions]="[25,30,50,75,100,125,150,200]">
          <ng-template fmTemplate="caption">
            <div style="text-align: right">
              <button fmButton type="button" [primary]="true" icon="fmi fmi-upload" label=" Upload Data Files"
                (click)="uploadFiles()" *ngIf="!disableUpload"></button>
            </div>
            <div style="text-align: left">
              <input placeholder="Type to filter" style="width:auto" fmTextInput type="text"
                [(ngModel)]="dataFilesFilterBinding" (input)="dftbl.filterGlobal($event.target.value, 'contains')" />
            </div>
          </ng-template>
          <ng-template fmTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [fmSortableColumn]="col.field" [style.width]="col.width">{{col.header}}
                <fm-sortIcon [field]="col.field"></fm-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template fmTemplate="body" let-rowData let-columns="columns">
            <tr align="center">
              <td *ngFor="let col of columns">
                <p *ngIf="col.field != 'submitDT'">{{rowData[col.field]}}</p>
                <p *ngIf="col.field == 'submitDT'">{{rowData[col.field] | date:'MM/dd/yyyy H:mm:ss'}}</p>
              </td>
            </tr>
          </ng-template>
        </fm-table>
        <div id="noFilesSubmitted" align="left" *ngIf="dataFilesDetailsDashboardDisplay.length === 0">
          <br>
          <i>{{tableNoSubmittedFilesMessage}}</i><a [routerLink]="[]" (click)="uploadFiles()"
            *ngIf="!disableUpload"><u>Upload data
              files.</u></a>
        </div>
      </div>

      <fm-dialog id="uploadModal" #dialog1 class="uploadModal" [width]="screenWidth" [height]="screenHeight"
        [alignLeft]="true" header="Add Data Files" [(visible)]="display1" [responsive]="true" [dismissableMask]="true"
        (onShow)="setDisplayFlex()" (onHide)="updateTable()">
        <fm-loading-spinner [isLoading]="isLoading"></fm-loading-spinner>
        <br>
        <div class="progress-bar">
          <div class="split-left" [class.selected-tab]="currentStep=='UploadFileStep'"
            [class.not-selected-tab]="currentStep=='ReviewFileSummaryStep'" (click)="backToUpload()">
            Upload Files
          </div>
          <div class="split-right" [class.selected-tab]="currentStep=='ReviewFileSummaryStep'"
            [class.not-selected-tab]="currentStep=='UploadFileStep'" (click)="backToReview()">
            Review File Summary
          </div>
        </div>
        <br>

        <div id="uploadFilesTab" align="center" *ngIf="currentStep=='UploadFileStep'">
          <div align="center" class="modalSubTitle">
            Upload files to perform a full file scan
          </div>
          <div align="center" class="modalSubSubtitle">
            The system will scan your files for viruses, correct naming conventions, <br> and data errors to ensure they
            are eligible to submit.
          </div>

          <div id="enabledUploadComponent" *ngIf="!fileUploadService.disableUpload && !isMAUser">
            <fm-s3-fileupload (onSelect)="onSelectFile($event)" (onFileUploadSubmit)="completeFileUpload($event)"
              (onUpload)="onUploadFile($event)" (onClear)="fileUploadService.onClearFile()"
              [getFileUploadStatus]="fileUploadService.statusUrl" appCode="mfdstdn" showUploadButton="true"
              showCancelButton="true" chooseLabel="Select File(s)" multiple="true" uploadLabel="Start Upload"
              cancelLabel="Remove All" continueLabel="Continue" mode="advanced"
              [getFileUploadSignedUrl]="fileUploadService.uploadUrl" maxFileSize="50432000"
              displayMessage="Maximum single file size supported: 50 MB" cdx="true"
              accept=".xml,.csv,.XML,.CSV,.xML,.cSV,.XmL,.CsV,.XMl,.CSv,.Xml,.Csv,.xMl,.cSv,.xmL,.csV">
            </fm-s3-fileupload>
          </div>
          <div id="enabledUploadComponent" *ngIf="!fileUploadService.disableUpload && isMAUser">
            <fm-s3-fileupload (onSelect)="onSelectFile($event)" (onFileUploadSubmit)="completeFileUpload($event)"
              (onUpload)="onUploadFile($event)" (onClear)="fileUploadService.onClearFile()"
              [getFileUploadStatus]="fileUploadService.statusUrl" appCode="mfdstdn" showUploadButton="true"
              showCancelButton="true" chooseLabel="Select File(s)" multiple="true" uploadLabel="Start Upload"
              cancelLabel="Remove All" continueLabel="Continue" mode="advanced"
              [getFileUploadSignedUrl]="fileUploadService.uploadUrl" maxFileSize="50432000"
              displayMessage="Maximum single file size supported: 50 MB" cdx="true"
              accept=".csv,.CSV,.cSV,.CsV,.CSv,.Csv,.cSv,.csV">
            </fm-s3-fileupload>
          </div>
          <div id="disabledUploadComponent" *ngIf="fileUploadService.disableUpload">
            <fm-s3-fileupload (onSelect)="onSelectFile($event)" (onFileUploadSubmit)="completeFileUpload($event)"
              (onUpload)="onUploadFile($event)" (onClear)="fileUploadService.onClearFile()"
              [getFileUploadStatus]="fileUploadService.statusUrl" appCode="mfdstdn" showUploadButton="false"
              showCancelButton="true" chooseLabel="Select File(s)" multiple="true" uploadLabel="Start Upload"
              cancelLabel="Remove All" continueLabel="Continue" mode="advanced"
              [getFileUploadSignedUrl]="fileUploadService.uploadUrl" maxFileSize="50432000"
              displayMessage="Maximum single file size supported: 50 MB" cdx="true"
              accept=".xml,.csv,.XML,.CSV,.xML,.cSV,.XmL,.CsV,.XMl,.CSv,.Xml,.Csv,.xMl,.cSv,.xmL,.csV">
            </fm-s3-fileupload>
          </div>

          <br>
        </div>

        <div id="reviewFilesTab" *ngIf="currentStep=='ReviewFileSummaryStep'">
          <fm-table [columns]="cols4" [value]="uploadedFilesArrayDisplay" scrollable="true"
            scrollHeight="{{modalTableHeight}}" sortMode="single" selectionMode="multiple"
            (selectionChange)="onSubmitSelection($event)">
            <ng-template fmTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns" [fmSortableColumn]="col.field">{{col.header}}
                  <fm-sortIcon [field]="col.field"></fm-sortIcon>
                </th>
                <th class="checkboxHeader">
                  <b>Select to Submit</b>
                </th>
              </tr>
            </ng-template>
            <ng-template fmTemplate="body" let-rowData let-columns="columns">
              <tr align="center" [fmSelectableRow]="rowData">
                <td *ngFor="let col of columns" (click)="showIssueSummaryPage(rowData)"
                  [ngClass]="{'issue-summary' : rowData[col.field].includes('errors;')}">
                  <i *ngIf="rowData[col.field]=='Ready to submit'" class="fmi fmi-check-circle accepted"
                    aria-hidden="true"></i>
                  <i *ngIf="rowData[col.field]=='Acceptable with warnings'" class="fmi fmi-exclamation-circle warning"
                    aria-hidden="true"></i>
                  <i *ngIf="rowData[col.field]=='Not acceptable'" class="fmi fmi-times-circle rejected"
                    aria-hidden="true"></i>
                  <i *ngIf="rowData[col.field]=='Error'" class="fmi fmi-times-circle rejected" aria-hidden="true"></i>
                  {{rowData[col.field]}}
                </td>
                <td class="checkbox">
                  <fm-tableCheckbox [value]="rowData"
                    *ngIf="rowData.status=='Ready to submit'||rowData.status=='Acceptable with warnings'">
                  </fm-tableCheckbox>
                </td>
              </tr>
            </ng-template>
          </fm-table>

          <div id="noFilesUploaded" align="center" *ngIf="uploadedFilesArrayDisplay.length === 0">
            <br>
            No files were uploaded.
          </div>

          <br>

          <div style="text-align: left">
            <button fmButton type="button" label="Back To Upload" (click)="backToUpload()"
              class="back-upload-button"></button>
          </div>
          <div class="finish-close-button" *ngIf="selectFilesTouched || !finishAndCloseClicked">
            <i class="submissionText">Selected files will be submitted upon completion, others will be discarded</i>
            <button fmButton type="button" [primary]="true" label="Finish And Close"
              (click)="finishAndClose()"></button>
          </div>
          <div class="finish-close-button" *ngIf="!selectFilesTouched && finishAndCloseClicked">
            <i class="submissionText">Are you sure you want to exit without submitting file(s)?</i>
            <button fmButton class="yes-button" type="button" [primary]="true" label="Yes"
              (click)="continueSubmission()"></button>
          </div>

        </div>
      </fm-dialog>

      <fm-confirmDialog id="uploadErrorModal" class="uploadErrorModal" key="confirm" [alignLeft]="true"
        acceptLabel="Back To Upload" [height]="modalScreenHeight" [width]="modalScreenWidth"></fm-confirmDialog>

    </fm-tabPanel>
  </fm-tabView>
  <br>
</div>

<div id="issueSummaryPreviewPage" *ngIf="showIssueSummaryPreview">
  <app-issue-summary-preview></app-issue-summary-preview>
</div>
<div>
    <div id="acquisitionSummaryTop">
        <!-- Property Acquisitions, Compliance Rate and Submission Rate -->
        <div id="acquisitionSummaryTiles">
            <fm-cards class="acqnTiles">
                <div class="info-icon">
                    <i class="fmi fmi-info-circle" aria-hidden="true" fmTooltip="Total number of Acquired Deals."
                        tooltipPosition="top"></i>
                </div>
                <div class="count-value" *ngIf="acquisitionStatusFilterSelected != 'Pre-Acquisition'">
                    {{numberOfAcquisitions | number}}
                </div>
                <div class="count-value" *ngIf="acquisitionStatusFilterSelected == 'Pre-Acquisition'">
                    {{numberOfAcquisitions}}
                </div>
                Property Acquisitions
            </fm-cards>
            <fm-cards class="acqnTilesMain">
                <div class="count-value" *ngIf="acquisitionStatusFilterSelected != 'Pre-Acquisition'">
                    {{complianceRateAcquisitions | percent}}
                </div>
                <div class="count-value" *ngIf="acquisitionStatusFilterSelected == 'Pre-Acquisition'">
                    {{complianceRateAcquisitions}}
                </div>
                Compliance Rate
            </fm-cards>
            <fm-cards class="acqnTiles">
                <div class="count-value" *ngIf="acquisitionStatusFilterSelected != 'Pre-Acquisition'">
                    {{submissionRateAcquisitions | percent}}
                </div>
                <div class="count-value" *ngIf="acquisitionStatusFilterSelected == 'Pre-Acquisition'">
                    {{submissionRateAcquisitions}}
                </div>
                Submission Rate
            </fm-cards>
        </div>
    </div>


    <div id="acquisitionSummaryBottom">

        <!-- From and To Lender -->
        <div id="acquisitionSelectionsTopRow">
            <div id="acquisitionSelectionsTopRowLeft">
                <div id="acquisitionPeriodSelectionStart" class="drop-down-selection">
                    <b class="drop-down-label">From</b>
                    <fm-select [options]="selectStartPeriod" [(ngModel)]="selectedStartPeriod"
                        placeholder="{{selectStartPeriod[0].value}}" (onChange)="onGetEndPeriodDates()"></fm-select>
                </div>
                <div id="acquisitionPeriodSelectionEnd" class="drop-down-selection">
                    <b class="drop-down-label">To</b>
                    <fm-select [options]="selectEndPeriod" [(ngModel)]="selectedEndPeriod"
                        (onChange)="onRefreshAcqnDetails()"></fm-select>
                </div>
            </div>
            <div id="acuisitionStatusSelection" class="drop-down-selection">
                <b class="drop-down-label">Acquisition Status</b>
                <div id="acuisitionStatusSelectionDropDown">
                    <fm-select [options]="acquisitionStatusFilterList" [(ngModel)]="acquisitionStatusFilterSelected"
                        (onChange)="applyFilters()"
                        placeholder="Select"></fm-select>
                </div>
            </div>
        </div>

        <!-- Lender, Supplemental, Loan Refinance Type, Small Loan Balance and Transaction Type-->
        <div id="acquisitionSelectionsBottomRow">
            <div id="acquisitionLenderSelection" class="drop-down-selection" *ngIf="isInternal">
                <b class="drop-down-label">Lender</b>
                <fm-select [options]="acqsnLenderList" [(ngModel)]="acqsnSelectedLender" [filter]="true"
                    [showFilter]="true" filterType="include"
                    (onChange)="applyFilters()" placeholder="Select">
                </fm-select>
            </div>
            <div id="lnPurpsTypeSelection" class="drop-down-selection">
                <b class="drop-down-label">Supplemental</b>
                <fm-select ariaLabelledBy="disable" [options]="lnPurpsTypeList" [(ngModel)]="lnPurpsTypeSelected"
                (onChange)="applyFilters()" defaultLabel="Select" [filter]="false">
                </fm-select>
            </div>
            <div id="lnRefiTypeSelection" class="drop-down-selection">
                <b class="drop-down-label">Loan Refinance Type</b>
                <fm-select ariaLabelledBy="disable" [options]="lnRefiTypeList" [(ngModel)]="lnRefiTypeSelected"
                (onChange)="applyFilters()" defaultLabel="Select" [filter]="false">
                </fm-select>
            </div>
            <div id="lnBalanceSelection" class="drop-down-selection">
                <b class="drop-down-label">Small Loan Balance</b>
                <fm-select ariaLabelledBy="disable" [options]="lnBalanceList" [(ngModel)]="lnBalanceSelected"
                (onChange)="applyFilters()" defaultLabel="Select" [filter]="false">
                </fm-select>
            </div>
            <div id="txTypeSelection" class="drop-down-selection">
                <b class="drop-down-label">Transaction Type</b>
                <fm-select ariaLabelledBy="disable" [options]="txTypeList" [(ngModel)]="txTypeSelected"
                (onChange)="applyFilters()" defaultLabel="Select" [filter]="false">
                </fm-select>
            </div>
        </div>
    </div>

    <div id="acquisitionDealsTable" class="submittedFilesTable">
        <fm-table #acqntbl [columns]="cols5" [value]="acqnDetailsDashboardDisplay" sortMode="single"
            [loadingIcon]="true" [paginator]="true" [rows]="30" [rowsPerPageOptions]="[25,30,50,75,100,125,150,200]"
            [(selection)]="selectedReportArrayDisplay">

            <!-- Type to filter and Update Report -->
            <ng-template fmTemplate="caption">
                <span id="filterUpdateReport">
                    <div style="text-align: left">
                        <input placeholder="Type to filter" style="width:auto" fmTextInput type="text"
                            [(ngModel)]="acquisitionsFilterBinding"
                            (input)="acqntbl.filterGlobal($event.target.value, 'contains')" />
                    </div>
                    <span *ngIf="showAcqnTableEditCheckbox">
                        <div style="text-align: right" *ngIf="selectedReportArrayDisplay.length != 0">
                            <button fmButton type="button" [primary]="true" icon="fmi fmi-edit" label=" Update Report"
                                (click)="showUpdateReportComponent()"></button>
                        </div>
                        <div style="text-align: right" *ngIf="selectedReportArrayDisplay.length == 0">
                            <button fmButton type="button" [primary]="true" icon="fmi fmi-edit" label=" Update Report"
                                (click)="showUpdateReportComponent()" disabled></button>
                        </div>
                    </span>
                </span>
            </ng-template>

            <!-- Table header -->
            <ng-template fmTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" [fmSortableColumn]="col.field" [style.width]="col.width">
                        {{col.header}}
                        <fm-sortIcon [field]="col.field"></fm-sortIcon>
                    </th>
                    <th *ngIf="showAcqnTableEditCheckbox" class="checkboxHeader"></th>
                </tr>
            </ng-template>

            <!-- Table body -->
            <ng-template fmTemplate="body" let-rowData let-columns="columns">
                <tr align="center">
                    <td *ngFor="let col of columns">
                        <p *ngIf="col.field != 'aqsnDt' && col.field != 'reqdSubmtDt' && col.field != 'maxSubmtDt'">
                            {{rowData[col.field]}}</p>
                        <p *ngIf="col.field == 'aqsnDt'">{{rowData[col.field] | date:'MM/dd/yyyy'}}</p>
                        <p *ngIf="col.field == 'reqdSubmtDt'">{{rowData[col.field] | date:'MM/dd/yyyy'}}</p>
                        <p *ngIf="col.field == 'maxSubmtDt'">{{rowData[col.field] | date:'MM/dd/yyyy'}}</p>
                    </td>
                    <td *ngIf="showAcqnTableEditCheckbox" class="checkbox">
                        <fm-tableCheckbox [value]="rowData"></fm-tableCheckbox>
                    </td>
                </tr>
            </ng-template>
        </fm-table>

        <div id="noDealsSubmitted" align="left" *ngIf="acqnDetailsDashboardDisplay.length === 0">
            <br>
            <i>{{tableNoAcqsnDealsMessage}}</i>
        </div>

        <br>
    </div>

    <!-- Update Report Component -->
    <app-acquisitions-update *ngIf="showAcqnUpdateComponent" [scrWidth]="screenWidth" [scrHeight]="screenHeight"
        [showAcqnUpdateComponent]="showAcqnUpdateComponent" [selectedReportArrayDisplay]="selectedReportArrayDisplay"
        (finishAndClosed)="onAcqnUpdateTableClose($event)">
    </app-acquisitions-update>
</div>
/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import { Observable, throwError as observableThrowError, throwError, timer } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { EndpointsService } from "./endpoints.service";
import { catchError, map, mergeMap, retryWhen, tap } from 'rxjs/internal/operators';
import { SelectedReports } from '../model/AcquisitionDetails';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  url: string;
  username: string;
  ssn: string;
  org: string;
  body: () => string;

  constructor(private httpClient: HttpClient, private apiService: ApiService, private endpointsService: EndpointsService) {
  }

  public getSubmittedFilesData(userId: string, dealId: string, reportType: string, cfmlycode: string, parentSSNList: string, userType: string): Observable<any> {
    this.url = this.endpointsService.setApiPath() + this.endpointsService.getEndpoints().getSubmittedFilesData + "?userid=" + userId + "&dealid=" + dealId + 
                               "&reporttype=" + reportType + "&cfmlycode=" + cfmlycode + "&parentSSNList=" + parentSSNList + "&userType=" + userType;
    return this.apiService.sendGetRequest(this.url);
  }

  public getAcquisitionData(selrsrvrnolist: string, startDate: string, endDate: string, isInternal: boolean): Observable<any> {
    this.url = this.endpointsService.setApiPath() + this.endpointsService.getEndpoints().getAcquisitionDashboardList;

    let params = new HttpParams()
      .set('selrsrvrnolist', selrsrvrnolist)
      .set('startPeriod', startDate)
      .set('endPeriod', endDate)
      .set('isInternal', isInternal);

    return this.httpClient.get(this.url, { params: params }).pipe(
      catchError(err => this.handleError(err))
    );
  }

  public getUserInformation() {
    let userInfo = [
      {
        "name": this.apiService.getUserDetails().firstName + " " + this.apiService.getUserDetails().lastName,
        "ssn": this.apiService.getSellerServicerNumber().sellerServicerNo,
        "org": this.apiService.getUserDetails().subscriberName
      }
    ];
    return userInfo;
  }

  public getFilesData(objectKey: string, userId: string, cfmlycode: string, validationonly: boolean, userType: string): Observable<any> {
    const fileStatusArrayValidation = ['Not acceptable', 'Ready to submit', 'Acceptable with warnings', 'Error'];
    const fileStatusArraySubmission = ['Not acceptable', 'Accepted', 'Accepted with warnings', 'Error'];
    let cnt = 5;
    let obs: Observable<any>;

    let params = new HttpParams()
      .set('userid', userId)
      .set('usertype', userType)
      .set('username', this.apiService.getUserDetails().firstName + " " + this.apiService.getUserDetails().lastName)
      .set('org', this.apiService.getUserDetails().subscriberName)
      .set('selrsrvrnolist', this.apiService.getSellerServicerNumber().sellerServicerNo)
      .set('objectkey', objectKey)
      .set('cfmlycode', cfmlycode)
      .set('validationonly', validationonly.toString())
      .set('version', "2");

    this.url = this.endpointsService.setApiPath() + this.endpointsService.getEndpoints().getFileStatus;

    if (validationonly) {
      obs = this.httpClient.get(this.url, { params: params }).pipe(
        map((res: any) => {
          if (fileStatusArrayValidation.includes(res.fileStatus.status)) {
            return res;
          } else {
            throw res;
          }
        }),
        retryWhen(
          mergeMap((error, cnt) => {
            const retryCnt = cnt + 1;
            if (retryCnt > 3) {
              return throwError(error);
            }
            return timer(2000);
          })
        )
      );
    } else {
      obs = this.httpClient.get(this.url, { params: params }).pipe(
        map((res: any) => {
          if (fileStatusArraySubmission.includes(res.fileStatus.status)) {
            return res;
          } else {
            throw res;
          }
        })
      );
    }

    return obs;
  }

  public setAcquisitionReportFlags(changedReportArrayDisplay: SelectedReports[]) : Observable<any> {
    let obs: Observable<any>;

    let url = this.endpointsService.setApiPath() + this.endpointsService.getEndpoints().setAcquisitionReportFlags;
    let localHeader = new HttpHeaders({ 'content-type': 'application/json' });
    let userData = changedReportArrayDisplay;

    obs = this.httpClient.post(url, userData, {headers: localHeader}).pipe(
      map((res: any) => {
          return res;
      }) 
    ).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
    return obs;
  }

  public handleError(error: Response) {
    return observableThrowError('Server error - is the REST server running?');
  }
}


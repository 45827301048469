/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {Component, OnInit} from "@angular/core";
import {MenuItem} from "@fm-ui-adk/components-2.0";
import {ApiService} from "../../services/api.service";
import {Utils} from '../../shared/utils';


export interface CustomIcon {
  title?: string;
  icon?: string;
  noCaret?: boolean;
  url?: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private utils: Utils, private apiService: ApiService) {
  }

  items: MenuItem[];
  userName = {
    "firstName": "",
    "lastName": ""
  };


  ngOnInit() {

    this.items = [
      {
        label: 'Logout',
        icon: 'fmi fmi-sign-out',
        command: () => {
          this.update();
        }
      }
    ];
    // Subscribe and update the info on demand
    this.apiService.userDetailsSub.subscribe((userDetails) => {
      this.userName['firstName'] = userDetails.firstName;
      this.userName['lastName'] = userDetails.lastName;
    })
  }

  update() {
    this.utils.logout();
  }
}

/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EndpointsService} from './endpoints.service';
import {Observable} from 'rxjs';
import {ApiService} from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class SellerServiceNumberService {

  private url: string;

  constructor(private httpClient: HttpClient, private apiService: ApiService,
              private endpointsService: EndpointsService) {
  }

  public getSellerServiceNumber(userId: string): Observable<any> {
    this.url = this.endpointsService.setApiPath() + this.endpointsService.getEndpoints().getSellerServicerData + "?userid=" + userId;
    return this.apiService.sendGetRequest(this.url);
  }
}

/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core'
import { FmComponentsModule } from '@fm-ui-adk/components-2.0/components_module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routes';
import { APICallService } from './api-call.service';
import { NotificationService } from './services/notification.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EndpointsService } from './services/endpoints.service';
import { HeaderComponent } from './component/header/header.component';
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTableModule } from "@angular/material/table";
import { LoginGuard } from './login.guard';
import { ApiService } from './services/api.service';
import { SellerServiceNumberService } from './services/sellerservicernumber.service';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { ErrorComponent } from './component/error/error.component';
import { DD_DashboardComponent } from './due-diligence/dashboard/dd-dashboard.component';
import { DashboardService } from "./services/dashboard.service";
import { FooterComponent } from "./component/footer/footer.component";
import { Utils } from './shared/utils';
import { IssueSummaryPreviewComponent } from './due-diligence/component/issue-summary-preview/issue-summary-preview.component';
import { RR_DashboardComponent } from './rent-roll/dashboard/rr-dashboard.component';
import { HomeComponent } from './home/home.component';
import { OS_DashboardComponent } from './operating-statements/dashboard/os-dashboard.component';
import { DataFilesComponent } from './due-diligence/data-files/data-files.component';
import { MockInterceptor } from './mock.interceptor';
import { AcquisitionsUpdateComponent } from './due-diligence/component/acquisitions-update/acquisitions-update.component';
import { AcquisitionsComponent } from './due-diligence/component/acquisitions/acquisitions.component';
import { LenderRankingComponent } from './due-diligence/component/lender-ranking/lender-ranking.component';
import { UserNotificationComponent } from './component/user-notification/user-notification.component';
import { SystemDownComponent } from './component/system-down/system-down.component';
import { LaunchDarklyUtil } from './shared/launch-darkly-util';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ErrorComponent,
    DD_DashboardComponent,
    FooterComponent,
    IssueSummaryPreviewComponent,
    RR_DashboardComponent,
    HomeComponent,
    OS_DashboardComponent,
    DataFilesComponent,
    AcquisitionsUpdateComponent,
    AcquisitionsComponent,
    LenderRankingComponent,
    UserNotificationComponent,
    SystemDownComponent
  ],
  imports: [
    FmComponentsModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatMenuModule,
    MatSidenavModule,
    MatTableModule,
    MatIconModule,
    AppRoutingModule,
    PortalModule
  ],
  exports: [
    FmComponentsModule,
    RouterModule],
  providers: [
    DashboardService,
    APICallService,
    NotificationService,
    EndpointsService,
    LoginGuard,
    SellerServiceNumberService,
    GlobalErrorHandlerService,
    ApiService,
    Utils,
    LaunchDarklyUtil,
    { provide: HTTP_INTERCEPTORS, useClass: ApiService, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: MockInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SelectedReports } from '../../../model/AcquisitionDetails';
import { DashboardService } from '../../../services/dashboard.service';

@Component({
  selector: 'app-acquisitions-update',
  templateUrl: './acquisitions-update.component.html',
  styleUrls: ['./acquisitions-update.component.css'],
  providers: [DashboardService]
})
export class AcquisitionsUpdateComponent implements OnInit {
  // @ViewChild('dialog1', { read: ElementRef }) dialogRef: ElementRef;

  @Input() scrWidth: number;
  @Input() scrHeight: number;
  @Input() showAcqnUpdateComponent: boolean;
  @Input() isLoading: boolean = false;
  @Input() selectedReportArrayDisplay: SelectedReports[] = [];
  @Output() finishAndClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  originalSelectedReportArrayDisplay: any = [];
  columnHeaders: any[];
  errorMessageSelection: string = "";
  errorMessageAPI: string = "";
  showSpinner: boolean = false;

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.columnHeaders = [
      { field: 'propNme', header: 'Property Name' },
      { field: 'pca', header: 'PCA', width: '6%', toggleFlag: 'pcaFlag', isModified: 'isPcaMod' },
      { field: 'esa', header: 'ESA', width: '6%', toggleFlag: 'esaFlag', isModified: 'isEsaMod' },
      { field: 'zon', header: 'ZON', width: '6%', toggleFlag: 'zonFlag', isModified: 'isZonMod' },
      { field: 'sra', header: 'SRA', width: '6%', toggleFlag: 'sraFlag', isModified: 'isSraMod' }
    ];

    // Loop through selectedReportArrayDisplay and set boolean column for toggle component
    this.addBooleanFlags();

    // Save the flags for comparision
    this.saveOriginalSelectedReportArrayDisplay();
  }

  addBooleanFlags() {
    for (let i = 0; i < this.selectedReportArrayDisplay.length; i++) {
      if (this.selectedReportArrayDisplay[i].pca == 'N') {
        this.selectedReportArrayDisplay[i].pcaFlag = false;
      } else if (this.selectedReportArrayDisplay[i].pca == 'N/A') {
        this.selectedReportArrayDisplay[i].pcaFlag = true;
      }

      if (this.selectedReportArrayDisplay[i].esa == 'N') {
        this.selectedReportArrayDisplay[i].esaFlag = false;
      } else if (this.selectedReportArrayDisplay[i].esa == 'N/A') {
        this.selectedReportArrayDisplay[i].esaFlag = true;
      }

      if (this.selectedReportArrayDisplay[i].zon == 'N') {
        this.selectedReportArrayDisplay[i].zonFlag = false;
      } else if (this.selectedReportArrayDisplay[i].zon == 'N/A') {
        this.selectedReportArrayDisplay[i].zonFlag = true;
      }

      if (this.selectedReportArrayDisplay[i].sra == 'N') {
        this.selectedReportArrayDisplay[i].sraFlag = false;
      } else if (this.selectedReportArrayDisplay[i].sra == 'N/A') {
        this.selectedReportArrayDisplay[i].sraFlag = true;
      }

      this.selectedReportArrayDisplay[i].isPcaMod = false;
      this.selectedReportArrayDisplay[i].isEsaMod = false;
      this.selectedReportArrayDisplay[i].isZonMod = false;
      this.selectedReportArrayDisplay[i].isSraMod = false;
    }
  }

  saveOriginalSelectedReportArrayDisplay() {
    for (let i = 0; i < this.selectedReportArrayDisplay.length; i++) {
      let rowData = {
        pca: this.selectedReportArrayDisplay[i].pca,
        esa: this.selectedReportArrayDisplay[i].esa,
        zon: this.selectedReportArrayDisplay[i].zon,
        sra: this.selectedReportArrayDisplay[i].sra,
        id: this.selectedReportArrayDisplay[i].id
      }
      this.originalSelectedReportArrayDisplay.push(rowData);
    }
  }

  getAcquisitionDashboardCounts() { }

  setErrorMessageSelection(errMsg) {
    this.errorMessageSelection = errMsg;
  }

  setErrorMessageAPI(errMsg) {
    this.errorMessageAPI = errMsg;
  }

  onCancelled() {
    for (let i = 0; i < this.selectedReportArrayDisplay.length; i++) {
      this.selectedReportArrayDisplay[i].pca = this.originalSelectedReportArrayDisplay[i].pca;
      this.selectedReportArrayDisplay[i].esa = this.originalSelectedReportArrayDisplay[i].esa;
      this.selectedReportArrayDisplay[i].zon = this.originalSelectedReportArrayDisplay[i].zon;
      this.selectedReportArrayDisplay[i].sra = this.originalSelectedReportArrayDisplay[i].sra;
    }
    this.finishAndClosed.emit(false);
  }

  onFinishAndClosed() {
    this.setErrorMessageSelection("");

    // Determine if anything changed
    let changedReportArrayDisplay: SelectedReports[] = this.getChangedRows();
    if (changedReportArrayDisplay.length == 0) {
      this.setErrorMessageSelection("No change detected.");
      return;
    }

    // Call the service to update flags
    this.showSpinner = true;
    this.dashboardService.setAcquisitionReportFlags(changedReportArrayDisplay).subscribe(
      res => {
        this.showSpinner = false;

        //Everything is good, close the dialog
        this.finishAndClosed.emit(true);
      },
      error => {
        this.showSpinner = false;
        this.setErrorMessageAPI("We're sorry, something went wrong on our end. Please try again later or contact our support team.");
      }
    )
  }

  getChangedRows() {
    let changedReportArrayDisplay: SelectedReports[] = [];
    for (let i = 0; i < this.selectedReportArrayDisplay.length; i++) {
      if ((this.originalSelectedReportArrayDisplay[i].pca != this.selectedReportArrayDisplay[i].pca) ||
        (this.originalSelectedReportArrayDisplay[i].esa != this.selectedReportArrayDisplay[i].esa) ||
        (this.originalSelectedReportArrayDisplay[i].zon != this.selectedReportArrayDisplay[i].zon) ||
        (this.originalSelectedReportArrayDisplay[i].sra != this.selectedReportArrayDisplay[i].sra)) {
        changedReportArrayDisplay.push(this.selectedReportArrayDisplay[i]);
      }
    }
    return changedReportArrayDisplay;
  }

  onChangeFlagValue(event, row, field, isModified) {
    this.setErrorMessageSelection("");
    this.setErrorMessageAPI("");

    if (event.checked) {
      row[field] = 'N/A';
    } else {
      row[field] = 'N';
    }

    let finding = this.findInArrayWhereID(this.originalSelectedReportArrayDisplay, row['id']);

    row[isModified] = !(row[field] == finding[0][field]);
  }

  findInArrayWhereID(arr, id) {
    return arr.filter((obj) => {
      return (obj.id == id)
    })
  }
}



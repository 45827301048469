/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

export type NotificationType = 'error' | 'success';

export interface Notification {
  type: NotificationType;
  message: string;
}

@Injectable()
export class NotificationService {
  private notification$: Subject<Notification> = new Subject<Notification>();

  notifyUsers(type: NotificationType, message: string) {
    this.notification$.next({type, message});
  }

  getNotification$(): Observable<any> {
    return this.notification$.asObservable();
  }

  constructor() {
  }
}

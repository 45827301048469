/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';


@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {


  constructor(private injector: Injector) {
  }

  public handleError(error: any) {
    let router = this.injector.get(Router);
    //let response = this.injector.get(Response);

    //console.log(`Request URL : ${router.url}`);
    //console.log(`Response from Backend : ${response.status}`);

    if (error instanceof HttpErrorResponse || error.error instanceof ErrorEvent || error.status === 'UNAUTHORIZED') {
      //Backend returns unsuccessful response codes such as 404, 500 etc.
      if (error.status === 401 || error.status === 500) {
        /*console.error('Backend returned status code: ', error.status);
        console.error('Response body:', error.message);*/
      } else {
        //A client-side or network error occurred.
        //console.error('An error occurred:', error.message);
      }
      router.navigate(['error']);
    }
  }

}

/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

export const apiEndpoints = {
  'mfdstdn.cdxms.devl-etss.aws.fanniemae.com': {
    'loginUrl': 'https://mfdstdn.cdxms.devl-etss.aws.fanniemae.com/mfdstcf',
    'redirectUri': '&redirect_uri=https://mfdstdn.cdxms.devl-etss.aws.fanniemae.com/',
    'logoutUrl': 'https://fmsso-devl.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com': {
    'loginUrl': 'https://mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com/mfdstcf?cdxRedirectPath=/',
    'redirectUri': '&redirect_uri=https://mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com/oauth2/idpresponse?redirectTo=https://mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com/',
    'logoutUrl': 'https://fmsso-devl.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstdn.cdxms.test-etss.aws.fanniemae.com': {
    'loginUrl': 'https://mfdstdn.cdxms.test-etss.aws.fanniemae.com/mfdstcf',
    'redirectUri': '&redirect_uri=https://mfdstdn.cdxms.test-etss.aws.fanniemae.com/',
    'logoutUrl': 'https://fmsso-test.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstcf.mfdstdn.mfbu.test.intgfanniemae.com': {
    'loginUrl': 'https://mfdstcf.mfdstdn.mfbu.test.intgfanniemae.com/mfdstcf?cdxRedirectPath=/',
    'redirectUri': '&redirect_uri=https://mfdstcf.mfdstdn.mfbu.test.intgfanniemae.com/oauth2/idpresponse?redirectTo=https://mfdstcf.mfdstdn.mfbu.test.intgfanniemae.com/',
    'logoutUrl': 'https://fmsso-test.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstdn.cdxms.acpt-etss.aws.fanniemae.com': {
    'loginUrl': 'https://mfdstdn.cdxms.acpt-etss.aws.fanniemae.com/mfdstcf/?cdxRedirectPath=/',
    'redirectUri': '&redirect_uri=https://mfdstdn.cdxms.acpt-etss.aws.fanniemae.com/',
    'logoutUrl': 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstcf.mfdstdn.mfbu.acpt.intgfanniemae.com': {
    'loginUrl': 'https://mfdstcf.mfdstdn.mfbu.acpt.intgfanniemae.com/mfdstcf/?cdxRedirectPath=/',
    'redirectUri': '&redirect_uri=https://mfdstcf.mfdstdn.mfbu.acpt.intgfanniemae.com/oauth2/idpresponse?redirectTo=https://mfdstcf.mfdstdn.mfbu.acpt.intgfanniemae.com/',
    'logoutUrl': 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping'
  },
  'dusdatadigitizer.acptfanniemae.com': {
    'loginUrl': 'https://dusdatadigitizer.acptfanniemae.com/mfdstcf/?cdxRedirectPath=/',
    'redirectUri': '&redirect_uri=https://dusdatadigitizer.acptfanniemae.com/oauth2/idpresponse?redirectTo=https://dusdatadigitizer.acptfanniemae.com/',
    'logoutUrl': 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping'
  },
  'dusdatadigitizer.fanniemae.com': {
    'loginUrl': 'https://dusdatadigitizer.fanniemae.com/mfdstcf/?cdxRedirectPath=/',
    'redirectUri': '&redirect_uri=https://dusdatadigitizer.fanniemae.com/oauth2/idpresponse?redirectTo=https://dusdatadigitizer.fanniemae.com/',
    'logoutUrl': 'https://fmsso.fanniemae.com/idp/startSLO.ping'
  }
};

export const logoutEndPoints = {
  'mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com': {
    'logoutUrl': 'https://fmsso-devl.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstcf.mfdstdn.mfbu.test.intgfanniemae.com': {
    'logoutUrl': 'https://fmsso-test.fanniemae.com/idp/startSLO.ping'
  },
  'mfdstcf.mfdstdn.mfbu.acpt.intgfanniemae.com': {
    'logoutUrl': 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping'
  }
};

export const environment = {
  environmentName: 'base',
  production: false,
  baseUrl: 'https://' + window.location.hostname,
  apiUrl: '',
  hostName: window.location.hostname,
  uploadEndpointDev: 'https://mfdstcf.mfdstdn.mfbu.devl.intgfanniemae.com',
  uploadEndpointTest: 'https://mfdstcf.mfdstdn.mfbu.test.intgfanniemae.com',
  uploadEndpointAcpt: 'https://mfdstcf.mfdstdn.mfbu.acpt.intgfanniemae.com',
  uploadEndpointPerf: 'https://mfdstdn-02.acpt.intgfanniemae.com',
  uploadEndpointProd: 'https://dusdatadigitizer.fanniemae.com',
  logoutEndpointDev: 'https://fmsso-devl.fanniemae.com/idp/startSLO.ping',
  logoutEndpointTest: 'https://fmsso-test.fanniemae.com/idp/startSLO.ping',
  logoutEndpointAcpt: 'https://fmsso-acpt.fanniemae.com/idp/startSLO.ping',
  logoutEndpointProd: 'https://fmsso.fanniemae.com/idp/startSLO.ping'
};


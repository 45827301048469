/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {EndpointsService} from "./endpoints.service";

@Injectable({
  providedIn: 'root'
})
export class OperatingStatementService {

  url: string;
  body;

  constructor(private httpClient: HttpClient, private endpointsService: EndpointsService) {
  }

  public getDashboardDetails(SSNArray, LenderName, SelectedMonthYear: string, PriorMonthFlag: boolean, InternalUser: boolean) {
    this.url = this.endpointsService.setApiPath() + this.endpointsService.getEndpoints().operatingStatementDetails;
    this.body = {
      "fnmaSellerNos": SSNArray,
      "lenderName": LenderName,
      "timeline": SelectedMonthYear,
      "priorMonthFlag": PriorMonthFlag,
      "internalUser": InternalUser
    };
    return this.httpClient.post(this.url, this.body);
  }

  public operatingStmtsSponsorPropertyList(SSNArray, InternalUser: boolean, LenderName, SelectedMonthYear: string) {
    this.url = this.endpointsService.setApiPath() + this.endpointsService.getEndpoints().operatingStmtsSponsorPropertyList;
    this.body = {
      "fnmaSellerNos": SSNArray,
      "lenderName": LenderName,
      "timeline": SelectedMonthYear,
      "priorMonthFlag": null,
      "internalUser": InternalUser
    };
    return this.httpClient.post(this.url, this.body);
  }

  public generateExcel(SSNArray, LenderName, StartPeriod: string, EndPeriod: string, PropertyList?: bigint[]) {
    this.url = this.endpointsService.setApiPath() + this.endpointsService.getEndpoints().operatingStatementReport;
    const headers = {'Content-Type': 'application/text'};
    this.body = {
      "fnmaSellerNos": SSNArray,
      "lenderName": LenderName,
      "startPeriod": StartPeriod,
      "endPeriod": EndPeriod,
      "propertyIds": PropertyList
    };
    return this.httpClient.post(this.url, this.body, {
      params: {doNotIntercept: 'true'}, ...headers,
      observe: 'response',
      responseType: 'text'
    });
  }

  public base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {type: contentType});
  }
}

/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {EndpointsService} from '../services/endpoints.service';
import {apiEndpoints} from '../../environments/environment';
import {formatDate} from "@angular/common";

@Injectable()
export class Utils {
  constructor(private endpointsService: EndpointsService, @Inject(LOCALE_ID) public locale: string) {
  };

  logout() {
    sessionStorage.clear();
    window.sessionStorage.clear(); // Just making sure this gets cleared in all browsers
    window.localStorage.clear();
    window.location.href = this.endpointsService.logoutFromApp();
  }

  login() {
    sessionStorage.clear();
    window.sessionStorage.clear(); // Just making sure this gets cleared in all browsers
    window.localStorage.clear();
    window.location.href = apiEndpoints[window.location.hostname].loginUrl;
  }

  public getBoolean(value) {
    switch (value) {
      case true:
      case "true":
      case 1:
      case "1":
      case "on":
      case "yes":
        return true;
      default:
        return false;
    }
  }

  public formatDate(value: Date, format: string) {
    return new Date(formatDate(value, format, this.locale, 'GMT'));
  }
  
  public removeArrayObjectWithId(arr, id) {
    return arr.filter((obj) => {
      return (obj.id !== id)
    });
  }

  public getUserType(isInternal: boolean, isAssetManager: boolean, isMAUser: boolean, isMFOpsUser: boolean) {
    if (isAssetManager)
      return 'a';
    else if (isMAUser) 
      return 'ma';
    else if (isMFOpsUser) 
      return 'ops';
    else if (isInternal) 
      return 'i';
    else
      return '';
  }
}

<div style="width: 100%; display: flex; justify-content: space-around">
  <div align="center"
       style="margin-top: 2vh; width: 33%; font-size:14px; background-color: #000F2B; color: whitesmoke; display: inline-block;">
    <h4>An internal error occurred while processing your request.<br> Redirecting to login page...</h4>
    <h2>If you are not redirected soon, please click <button (click)="login()">Logout</button></h2>
  </div>
<!--  <br>
  <div align="center">
    <button (click)="logout()">Logout</button>
  </div>-->
</div>

<!--<body>
<h2 class="app-name">Error</h2>
<p class="app-name">An error occurred while processing your request</p>
</body>-->

/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {Component, OnInit} from '@angular/core';
import {DD_DashboardComponent} from "../../dashboard/dd-dashboard.component";
import {Location} from "@angular/common";
import {AppConstants} from "../../../shared/app.constants";
import * as _ from "lodash";
import {FmTableComponent} from '@fm-ui-adk/components-2.0/fm-table'

@Component({
  selector: 'app-issue-summary-preview',
  templateUrl: './issue-summary-preview.component.html',
  styleUrls: ['./issue-summary-preview.component.css']
})
export class IssueSummaryPreviewComponent implements OnInit {
  showErrors: boolean;
  showErrorsMaster: boolean;
  showWarnings: boolean;
  showWarningsMaster: boolean;
  fileRejectedError: boolean;
  fileRejectedErrorWarning: boolean;
  fileWarning: boolean;
  errorsList = [];
  errors = [];
  errorsRow = [];
  errorsMaster = [];
  groupedErrorDisplay = [];
  warnings = [];
  warningsRow = [];
  warningsMaster = [];
  groupedWarningDisplay = [];
  uploadedFileName;
  masterRecord: boolean;
  ifPcaCsv: boolean;
  ifOther:boolean
  ifPca: boolean;
  ifBpo:boolean;
  ifBpoCsv:boolean;
  ifXml: boolean;
  internalError: boolean;
  serverErrorMessage: string;
  cols5;
  cols6;
  cols7;
  cols8;
  genericReportPreviewCols;
  pcaXmlIREPreviewCols;
  pcaXmlCICEPreviewCols;
  pcaCsvIREPreviewCols;
  pcaCsvCICEPreviewCols;
  displayArray: any[] = [];
  displayArrayPcaXmlIRE: any[] = [];
  displayArrayPcaXmlCICE: any[] = [];
  displayArrayPcaCsvIRE: any[] = [];
  displayArrayPcaCsvCICE: any[] = [];
  showNoErrorsWarningsMessage: boolean = false;
  showReportedFieldsNullMessage: boolean = false;
  filename: string = "";
  timestamp: string = "";
  expanded: boolean = false;
  printPreview: boolean = false;
  printErrorsWarnings: boolean = false;
  fileFormat: string;

  constructor(private dashboardComponent: DD_DashboardComponent, private location: Location) {
  }

  ngOnInit() {
    this.cols5 = [
      {field: 'row', header: 'Row #'},
      {field: 'errors', header: 'Error Message'}
    ];

    this.cols6 = [
      {field: 'row', header: 'Row #'},
      {field: 'warnings', header: 'Warning Message'}
    ];

    this.cols7 = [
      {field: 'em', header: 'Error Message'}
    ];

    this.cols8 = [
      {field: 'warnings', header: 'Warning Message'}
    ];

    this.genericReportPreviewCols = [
      {field: 'displayName', header: ""},
      {field: 'value', header: ""}
    ];

    this.pcaXmlIREPreviewCols = [
      {field: 'Action Code ', header: "Action Code"},
      {field: 'Immediate Repair Category ', header: "Immediate Repair Category"},
      {field: 'Immediate Repair Item ', header: "Immediate Repair Item"},
      {field: 'Immediate Repair Cost ', header: "Immediate Repair Cost"}
    ];

    this.pcaXmlCICEPreviewCols = [
      {field: 'Action Code ', header: "Action Code"},
      {field: 'Capital Item Category ', header: "Capital Item Category"},
      {field: 'Capital Item ', header: "Capital Item"},
      {
        field: 'Capital Item Total Cost Over Evaluation Period ',
        header: "Capital Item Total Cost Over Evaluation Period"
      },
      {field: 'Capital Item Remaining Useful Life ', header: "Capital Item Remaining Useful Life"},
      {field: 'Capital Item Effective Age ', header: "Capital Item Effective Age"}
    ];

    this.pcaCsvIREPreviewCols = [
      {field: 'Action Code ', header: "Action Code"},
      {field: 'Immediate Repair Category ', header: "Immediate Repair Category"},
      {field: 'Row', header: "Row #"},
      {field: 'Deal Identifier ', header: "Deal Identifier"},
      {field: 'Collateral Reference Number ', header: "Collateral Reference Number"},
      {field: 'Immediate Repair Item ', header: "Immediate Repair Item"},
      {field: 'Immediate Repair Cost ', header: "Immediate Repair Cost"}
    ];

    this.pcaCsvCICEPreviewCols = [
      {field: 'Action Code ', header: "Action Code"},
      {field: 'Capital Item Category ', header: "Capital Item Category"},
      {field: 'Row', header: "Row #"},
      {field: 'Deal Identifier ', header: "Deal Identifier"},
      {field: 'Collateral Reference Number ', header: "Collateral Reference Number"},
      {field: 'Capital Item ', header: "Capital Item"},
      {
        field: 'Capital Item Total Cost Over Evaluation Period ',
        header: "Capital Item Total Cost Over Evaluation Period"
      },
      {field: 'Capital Item Remaining Useful Life ', header: "Capital Item Remaining Useful Life"},
      {field: 'Capital Item Effective Age ', header: "Capital Item Effective Age"}
    ];

    this.filename = this.dashboardComponent.rowData.filename;
    this.fileFormat = this.filename.split(".")[this.filename.split(".").length - 1].toUpperCase();
    this.timestamp = "Uploaded " + this.dashboardComponent.rowData.receivedDT;
    this.mapRowToFile(this.dashboardComponent.uploadedFilesArrayTemp, this.dashboardComponent.rowData);
    this.formulateErrors(this.dashboardComponent.rowData);
  }

  public formulateErrors(rowData) {
    this.showErrors = false;
    this.showErrorsMaster = false;
    this.showWarnings = false;
    this.showWarningsMaster = false;
    this.fileRejectedError = false;
    this.fileRejectedErrorWarning = false;
    this.fileWarning = false;
    this.errorsList = [];
    this.errors = [];
    this.errorsRow = [];
    this.errorsMaster = [];
    this.groupedErrorDisplay = [];
    this.warnings = [];
    this.warningsRow = [];
    this.warningsMaster = [];
    this.groupedWarningDisplay = [];
    this.uploadedFileName = rowData.filename;
    this.masterRecord = false;
    this.ifPcaCsv = false;
    this.ifBpoCsv = false;
    this.ifPca = false;
    this.ifBpo = false;
    this.ifOther = false;
    this.ifXml = false;
    this.internalError = false;
    this.serverErrorMessage = "";

    if (rowData.status == "Ready to submit")
      this.showNoErrorsWarningsMessage = true;

    //Add error message to API Error files
    if (rowData.status == "Error") {
      this.serverErrorMessage = AppConstants.GENERAL_ERROR_MSG_SERVER;
      this.internalError = true;
    }

    //check if file is PCA and CSV
    let fileType = this.uploadedFileName.split(".")[this.uploadedFileName.split(".").length - 1];
    let reportType = this.uploadedFileName.split("_")[1];
    if (reportType.toUpperCase().includes("PCA")) {
      this.ifPca = true;
    }else if (reportType.toUpperCase().includes("BPO")) {
      this.ifBpo= true;
    }else{
      this.ifOther = true;
    }

    if (fileType.toUpperCase() == "CSV" && this.ifPca) {
      this.ifPcaCsv = true;
    } else if(fileType.toUpperCase() == "CSV" && this.ifBpo){
      this.ifBpoCsv = true;
    }else {
      for (let i = 0; i < rowData.errorsList.length; i++) {
        rowData.errorsList[i].row = null;
      }
    }
    if (fileType.toUpperCase() == "XML") {
      this.ifXml = true;
    }

    for (let k = 0; k < rowData.errorsList.length; k++) {
      if (!rowData.errorsList[k].row) {
        this.masterRecord = true;
        if (rowData.errorsList[k].errors != null && rowData.errorsList[k].errors.length > 0) {
          this.showErrorsMaster = true;
          for (let i = 0; i < rowData.errorsList[k].errors.length; i++) {
            if (this.ifXml) {
              if (!this.errorsMaster.includes(rowData.errorsList[k].errors[i])) {
                this.errorsMaster.push(rowData.errorsList[k].errors[i]);
              }
            } else {
              this.errorsMaster.push(rowData.errorsList[k].errors[i]);
            }
          }
        }
        if (rowData.errorsList[k].warnings != null && rowData.errorsList[k].warnings.length > 0) {
          this.showWarningsMaster = true;
          for (let i = 0; i < rowData.errorsList[k].warnings.length; i++) {
            if (this.ifXml) {
              if (!this.warningsMaster.includes(rowData.errorsList[k].warnings[i])) {
                this.warningsMaster.push(rowData.errorsList[k].warnings[i]);
              }
            } else {
              this.warningsMaster.push(rowData.errorsList[k].warnings[i]);
            }

          }
        }
      } else {
        if (rowData.errorsList[k].errors != null && rowData.errorsList[k].errors.length > 0) {
          this.showErrors = true;
          for (let i = 0; i < rowData.errorsList[k].errors.length; i++) {
            this.errors.push(rowData.errorsList[k].errors[i]);
            this.errorsRow.push({row: rowData.errorsList[k].row, errors: rowData.errorsList[k].errors[i]});
          }
        }
        if (rowData.errorsList[k].warnings != null && rowData.errorsList[k].warnings.length > 0) {
          this.showWarnings = true;
          for (let i = 0; i < rowData.errorsList[k].warnings.length; i++) {
            this.warnings.push(rowData.errorsList[k].warnings[i]);
            this.warningsRow.push({row: rowData.errorsList[k].row, warnings: rowData.errorsList[k].warnings[i]});
          }
        }
      }
    }

    if ((this.showErrors || this.showErrorsMaster) && (this.showWarnings || this.showWarningsMaster)) {
      this.fileRejectedErrorWarning = true;
    } else if ((this.showWarnings || this.showWarningsMaster) && !(this.showErrors || this.showErrorsMaster)) {
      this.fileWarning = true;
    } else if (!(this.showWarnings || this.showWarningsMaster) && (this.showErrors || this.showErrorsMaster)) {
      this.fileRejectedError = true;
    }

    this.createDisplayErrorArray();
    this.createDisplayWarningArray();
  }

  createDisplayErrorArray() {
    this.groupedErrorDisplay = _(this.errorsRow)
      .groupBy('errors')
      .map(function (array, key) {
        return {
          errors: key,
          row: _.map(array, 'row')
        };
      })
      .value();
  }

  createDisplayWarningArray() {
    this.groupedWarningDisplay = _(this.warningsRow)
      .groupBy('warnings')
      .map(function (array, key) {
        return {
          warnings: key,
          row: _.map(array, 'row')
        };
      })
      .value();
  }

  mapRowToFile(uploadedFilesArray, rowData) {
    let objectKey = rowData.objectKey;
    for (let i = 0; i < uploadedFilesArray.length; i++) {
      if (uploadedFilesArray[i].fileStatus.objectKey == objectKey) {
        if (uploadedFilesArray[i].reportedFields != null)
          this.formulatePreview(uploadedFilesArray[i].reportedFields);
        else
          this.showReportedFieldsNullMessage = true;
      }
    }
  }

  formulatePreview(array) {
    this.displayArray = [];
    this.displayArrayPcaXmlIRE = [];
    this.displayArrayPcaXmlCICE = [];
    this.displayArrayPcaCsvIRE = [];
    this.displayArrayPcaCsvCICE = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i].type == 'g' && array[i].displayName == 'Immediate Repairs Estimation' && this.fileFormat == 'XML' && array[i + 1].type == 'rh') {
        this.displayArray.push({"displayName": array[i].displayName, "expand": [], "pcaXmlIRE": true});
      } else if (array[i].type == 'g' && array[i].displayName == 'Immediate Repairs Estimation' && this.fileFormat == 'CSV' && array[i + 1].type == 'rh') {
        this.displayArray.push({"displayName": array[i].displayName, "expand": [], "pcaCsvIRE": true});
      } else if (array[i].type == 'g' && array[i].displayName == 'Capital Items Cost Evaluation' && this.fileFormat == 'XML' && array[i + 1].type == 'rh') {
        this.displayArray.push({"displayName": array[i].displayName, "expand": [], "pcaXmlCICE": true});
      } else if (array[i].type == 'g' && array[i].displayName == 'Capital Items Cost Evaluation' && this.fileFormat == 'CSV' && array[i + 1].type == 'rh') {
        this.displayArray.push({"displayName": array[i].displayName, "expand": [], "pcaCsvCICE": true});
      } else if (array[i].type == 'g') {
        this.displayArray.push({"displayName": array[i].displayName, "expand": []});
      } else if (array[i].type == 'r' && this.displayArray[this.displayArray.length - 1].pcaXmlIRE) {
        this.displayArrayPcaXmlIRE.push({
          "Action Code ": array[i].value[0],
          "Immediate Repair Category ": array[i].value[1],
          "Immediate Repair Item ": array[i].value[2],
          "Immediate Repair Cost ": array[i].value[3]
        });
      } else if (array[i].type == 'r' && this.displayArray[this.displayArray.length - 1].pcaCsvIRE) {
        this.displayArrayPcaCsvIRE.push({
          "Action Code ": array[i].value[0],
          "Immediate Repair Category ": array[i].value[1],
          "Row": array[i].value[2],
          "Deal Identifier ": array[i].value[3],
          "Collateral Reference Number ": array[i].value[4],
          "Immediate Repair Item ": array[i].value[5],
          "Immediate Repair Cost ": array[i].value[6]
        });
      } else if (array[i].type == 'r' && this.displayArray[this.displayArray.length - 1].pcaXmlCICE) {
        this.displayArrayPcaXmlCICE.push({
          "Action Code ": array[i].value[0],
          "Capital Item Category ": array[i].value[1],
          "Capital Item ": array[i].value[2],
          "Capital Item Total Cost Over Evaluation Period ": array[i].value[3],
          "Capital Item Remaining Useful Life ": array[i].value[4],
          "Capital Item Effective Age ": array[i].value[5]
        });
      } else if (array[i].type == 'r' && this.displayArray[this.displayArray.length - 1].pcaCsvCICE) {
        this.displayArrayPcaCsvCICE.push({
          "Action Code ": array[i].value[0],
          "Capital Item Category ": array[i].value[1],
          "Row": array[i].value[2],
          "Deal Identifier ": array[i].value[3],
          "Collateral Reference Number ": array[i].value[4],
          "Capital Item ": array[i].value[5],
          "Capital Item Total Cost Over Evaluation Period ": array[i].value[6],
          "Capital Item Remaining Useful Life ": array[i].value[7],
          "Capital Item Effective Age ": array[i].value[8]
        });
      } else if (array[i].type != 'rh') {
        if (array[i].value != "null") {
          this.displayArray[this.displayArray.length - 1].expand.push({
            "displayName": array[i].displayName,
            "value": array[i].value
          });
        } else {
          this.displayArray[this.displayArray.length - 1].expand.push({
            "displayName": array[i].displayName,
            "value": ""
          });
        }
      }
    }
  }

  printScreen() {
    window.print();
  }

  printTable() {
    let printContents, printWindow;
    printContents = document.getElementById('file-preview').innerHTML;
    printWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Preview</title>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    printWindow.document.close();
  }

  setPrintSettings(divToPrint) {
    this.printPreview = false;
    this.printErrorsWarnings = false;

    if (divToPrint == 'file-preview')
      this.printPreview = true;
    else if (divToPrint == 'errors-and-warnings')
      this.printErrorsWarnings = true;
  }

  return() {
    this.dashboardComponent.showIssueSummaryPreview = false;
    this.dashboardComponent.dataFilesTabSelected = true;
  }

  //TODO: Remove method when ADK is updated to incorporate method that toggles all rows
  toggleAllRows(table: FmTableComponent, action?: string) {
    if (action && action == "expand") {
      table.value.forEach(function (row) {
        if (row["expand"] && !table.isRowExpanded(row)) {
          table.toggleRow(row);
        }
      });
      this.expanded = true;
    } else if (action && action == "collapse") {
      table.value.forEach(function (row) {
        if (row["expand"] && table.isRowExpanded(row)) {
          table.toggleRow(row);
        }
      });
      this.expanded = false;
    } else {
      table.value.forEach(function (row) {
        if (row["expand"]) {
          table.toggleRow(row);
        }
      });
      this.expanded = !this.expanded;
    }
  }
}

import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "./services/api.service";
import {Observable} from "rxjs";
import {apiEndpoints, environment} from '../environments/environment';
import {EndpointsService} from "./services/endpoints.service";

@Injectable({
  providedIn: 'root'
})

export class LoginGuard implements CanActivate {
  constructor(private http: HttpClient, private router: Router, private apiService: ApiService, private endpointsService: EndpointsService) {
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    //store details from docway
    let mf_deal_id = next.queryParams.mf_deal_id;
    let mf_doc_type = next.queryParams.mf_doc_type;
    let mfcd_deal_name = next.queryParams.mfcd_deal_name;

    mf_deal_id = (mf_deal_id === 'null' || mf_deal_id === 'Null') ? '' : mf_deal_id;
    mf_doc_type = (mf_doc_type === 'null' || mf_doc_type === 'Null') ? '' : mf_doc_type;
    mfcd_deal_name = (mfcd_deal_name == 'null' || mfcd_deal_name === 'Null') ? '' : mfcd_deal_name;

    if (mf_deal_id || mf_doc_type || mfcd_deal_name) {
      sessionStorage.setItem('mf_deal_id', mf_deal_id);
      sessionStorage.setItem('mf_doc_type', mf_doc_type);
      sessionStorage.setItem('mfcd_deal_name', mfcd_deal_name);
    }

    return this.allChecks(next, state);
  }


  public redirectToLogin() {
    // setting a delay, just to make sure query params are successfully stored in session storage in canActive
    setTimeout(() => {
      window.location.href = apiEndpoints[window.location.hostname].loginUrl;
      }, 2000);
  }

  private allChecks(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.getUserInfo().then(resp => {
        if (resp == null || resp == undefined) this.redirectToLogin();
        else {
          this.apiService.saveUserDetails(resp);
          observer.next(true);
          observer.complete();
        }
      }, err => {
        this.redirectToLogin()
      });
    });
  }


  private async getUserInfo() {
    const url = environment.baseUrl + this.endpointsService.getEndpoints().cdxUserInfoUrl;
    try {
      return await this.http.get(url, {...this.getHeader()}).toPromise();
    } catch (ex) {
      return null;
    }
  }

  getHeader(additionalHeaders: any = {}) {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': '*',
      ...additionalHeaders,
    };
    const currentUser: any = window.localStorage.getItem('currentUser');
    if (currentUser) {
      headers['x-fnma-app-user'] = currentUser;
    }
    return {
      headers: new HttpHeaders(headers),
    };
  }

  clearSessionId() {
    window.localStorage.clear();
    localStorage.clear();
    sessionStorage.clear();
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-system-down',
  templateUrl: './system-down.component.html',
  styleUrls: ['./system-down.component.css']
})
export class SystemDownComponent implements OnInit {

  @Input() title = "";
  @Input() message = "";
  
  constructor() { }

  ngOnInit(): void {
  }

}

/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {Injectable} from '@angular/core';
import {DocwayDetails} from "../model/DocwayDetails";

@Injectable({
  providedIn: 'root'
})

export class DocWayDetailsService {
  mf_deal_id: string;
  mf_doc_type: string;
  mfcd_deal_name: string;
  mf_doc_type_display: string;
  docwayDetailsArray: DocwayDetails[] = [];
  hasDealId: boolean = false;
  hasReportType: boolean = false;

  constructor() {
  }

  public getDetails() {
    this.mf_deal_id = window.sessionStorage.getItem('mf_deal_id');
    this.mf_doc_type = window.sessionStorage.getItem('mf_doc_type');
    this.mfcd_deal_name = window.sessionStorage.getItem('mfcd_deal_name');

    if (!this.mf_deal_id)
      this.mf_deal_id = "";
    if (!this.mf_doc_type)
      this.mf_doc_type = "";
    if (!this.mfcd_deal_name)
      this.mfcd_deal_name = "";

    window.history.replaceState({}, document.title, '/');

    //update report type value with abbreviation and make sure mf_doc_type_display has value
    if (this.mf_doc_type) {
      if (this.mf_doc_type == "Zoning Report")
        this.mf_doc_type_display = "Zoning Report (ZON)";
      else if (this.mf_doc_type == "Seismic Risk Assessment")
        this.mf_doc_type_display = "Seismic Risk Assessment (SRA)";
      else if (this.mf_doc_type == "Environmental Site Assessment")
        this.mf_doc_type_display = "Environmental Site Assessment (ESA)";
      else if (this.mf_doc_type == "Property Condition Assessment")
        this.mf_doc_type_display = "Property Condition Assessment (PCA)";
    } else {
      this.mf_doc_type_display = "";
    }

    //store details from DocWay
    this.docwayDetailsArray.push({
      mf_deal_id: this.mf_deal_id,
      mf_doc_type: this.mf_doc_type_display,
      mfcd_deal_name: this.mfcd_deal_name
    });

    //Set flags to check for DealId and ReportType
    if (this.docwayDetailsArray[0].mf_deal_id)
      this.hasDealId = true;

    if (this.docwayDetailsArray[0].mf_doc_type)
      this.hasReportType = true;
  }
}

import { Injectable } from "@angular/core";
import * as LDClient from "launchdarkly-js-client-sdk";
import { launchDarklyKeys } from "../../environments/environment.prod";

@Injectable()
export class LaunchDarklyUtil {

  sdk_value: string;
  ld_client: string;

  constructor() {
  };

  getLaunchDarkleyKeys() {
    const hostname = window.location.hostname;
    //console.log("hostname: " + hostname);

    const key = launchDarklyKeys[hostname];
    if (key) {
      this.sdk_value = key.sdkKey;
      //console.log("sdk key: " + this.sdk_value);
      this.ld_client = key.clientId;
      //console.log("client id: " + this.ld_client);
    }

  }

  getLaunchDarklyShutDown(caller, myCallBack) {
    this.getLaunchDarkleyKeys();

    const context = {
      "kind": "user",
      "key": this.sdk_value
    };
    let client: LDClient.LDClient = LDClient.initialize(this.ld_client, context);

    client.on('ready', () => {
      // initialization succeeded, flag values are now available
      const flagValue = client.variation('mf-digitizer-hello', false);
      // console.log('allFlags=', this.client.allFlags());

      let systemDownFlagValue = client.variation('mf-digitizer-system-down', false);
      myCallBack(systemDownFlagValue, caller);
    });
  }

  getLaunchDarklyNotification(caller, myCallBack) {
    const context = {
      "kind": "user",
      "key": this.sdk_value
    };
    let client: LDClient.LDClient = LDClient.initialize(this.ld_client, context);

    client.on('ready', () => {
      let systemNotificationFlagValue = client.variation('mf-digitizer-system-notification', false);
      myCallBack(systemNotificationFlagValue, caller);
    });
  }
}

/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {Component, OnInit} from '@angular/core';
import {EndpointsService} from "../../services/endpoints.service";
import {LoginGuard} from "../../login.guard";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  logoutURL: any;

  constructor(private endpointsService: EndpointsService, private loginGuard: LoginGuard) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.login();
    }, 3000);
  }

  login() {
    this.loginGuard.clearSessionId();
    this.loginGuard.redirectToLogin();
  }
}

/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

export class AppConstants {
  //General Application
  public static GENERAL_ERROR_MSG_SERVER = "Internal Error – Please contact Fannie Mae (2000)";
  public static GENERAL_ERROR_MSG_UI = "Internal Error – Please contact Fannie Mae (1000)";
  public static SUPPORT_EMAIL = "data_standardization@fanniemae.com";
  public static SUPPORT_PHONE_NUMBER = "1-800-2FANNIE";
  public static SUPPORT_WEBSITE = "https://multifamily.fanniemae.com/applications-technology/dus-data-digitizer";

  //Home Page
  public static HOME_TITLE = "Welcome to DUS Data Digitizer™";
  public static HOME_UNAUTHORIZED_MESSAGE = "You do not have access to any of the applications.";

  //Due Diligence Reports
  public static DD_TABLE_SUBTITLE = "Showing activity in the last one year.";
  public static DD_TABLE_NO_SUBMITTED_FILES_MESSAGE = "No activity in the past one year. ";
  public static MAX_FILE_UPLOAD_LIMIT = 25;
  public static START_PERIOD_DD = "October 2020";

  //DD File Naming Validation Error Messages
  public static FILE_NAMING_ERROR_HEADER = "We detected a file naming error.";
  public static FILE_NAMING_ERROR_MSG_ALL = "Please correct the following file name(s) and restart the upload process. File name(s) should begin with either the Deal Identifier or Collateral Reference Number and Report Type.";
  public static FILE_NAMING_ERROR_MSG_DEALID = "Please correct the following file name(s) and restart the upload process. File name(s) should begin with the Deal Identifier and Report Type.";
  public static FILE_NAMING_ERROR_MSG_CRN = "Please correct the following file name(s) and restart the upload process. File name(s) should begin with the Collateral Reference Number and Report Type.";
  public static FILE_NAMING_ERROR_MSG_APPR = "The Deal Identifier is either missing or incorrect in the file name. Please confirm the correct DUS Gateway Deal Identifier is included in the file name.";
  public static FILE_TYPE_ERROR_HEADER = "We detected the wrong report type uploaded.";
  public static FILE_TYPE_ERROR_MSG = "The permissions for your role does not include the upload of the file(s) listed below. Please contact the support team <b><i>data_standardization@fanniemae.com</i></b> should you have questions or if you believe this message has been received erroneously.";
  public static FILE_FORMAT_ERROR_HEADER = "We detected the wrong file format uploaded.";
  public static FILE_FORMAT_ERROR_MSG_APPR = "Please correct the following file(s) and restart the upload process. Appraisal(s) should be uploaded in XML format.";
  public static MAX_FILE_UPLOAD_LIMIT_HEADER = "We detected too many files uploaded.";
  public static MAX_FILE_UPLOAD_LIMIT_MSG = "Please limit your selection to up to 25 files per single upload.";
  public static FILE_UPLOAD_ERROR_NOTE = "<b>Note!</b> You will need to restart the upload process for all files included in the same upload batch.";

  //Acquisition Deals
  public static ACQSN_TABLE_NO_ACQSN_DEALS_MESSAGE = "No acquisition deals in the selected period. ";

  //Rent Roll and Operating Statement
  public static START_PERIOD_RR = "February 2022";
  public static START_PERIOD_OS = "March 2022";
  public static START_PERIOD_SELECTION_TEXT = "From";
  public static END_PERIOD_SELECTION_TEXT = "To";
  public static PROPERTY_SELECTION_TEXT = " - Select - ";
  public static NO_DATA_FOR_SELECTION = "No data available for the selected date period or property.";
  public static NO_PROPERTIES_MAPPED_TO_USER_RR = "No Rent Roll Data available to download.";
  public static NO_PROPERTIES_MAPPED_TO_USER_OS = "No Operating Statement Data available to download.";
  public static INTERNAL_USER_MESSAGE = "Logged in as Internal User, NO SSN loaded.";
  public static TOO_MANY_PROPERTIES_RESULTED_FOR_SELECTION_RR = "More than 15,000 units were returned for your request. Please re-define your request timeframe or select a property.";
  public static TOO_MANY_PROPERTIES_RESULTED_FOR_SELECTION_OS = "More than 1,000 operating statements were returned for your request. Please re-define your request timeframe or select a property.";
}

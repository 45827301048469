<div class="page-wrapper">
  <div class="sticky-header no-print">
    <app-header></app-header>
  </div>
  <div class="content-wrapper">
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="sticky-footer no-print">
    <app-footer></app-footer>
  </div>
  <span id="userName">{{userName.firstName}} {{userName.lastName}}</span>
  <span id="userEmail">{{userEmail.emailAddress ? userEmail.emailAddress : userEmail.mail}}</span>
  <span id="userType">{{userType.userType}}</span>
</div>

/***************************************************************************************************

 Copyright 2023-2024 Fannie Mae. All rights reserved.
 This software contains confidential information and trade secrets of Fannie Mae.
 Use, disclosure, or reproduction is prohibited without the prior written consent of Fannie Mae.

 ****************************************************************************************************/

import {Component, OnInit} from '@angular/core';
import {ApiService} from "./services/api.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private apiService: ApiService) {
  }

  userName = {
    "firstName": "",
    "lastName": ""
  };
  userEmail = {
    "emailAddress": "",
    "mail": ""
  }
  userType = {
    "userType": ""
  }

  ngOnInit() {
    // Subscribe and update the info on demand
    this.apiService.userDetailsSub.subscribe((userDetails) => {
      this.userName['firstName'] = userDetails.firstName;
      this.userName['lastName'] = userDetails.lastName;
      this.userEmail['emailAddress'] = userDetails.emailAddress;
      this.userEmail['mail'] = userDetails.mail;
      this.userType['userType'] = userDetails.userType;
    })
  }
}

<div id="home-component" class="home">
  <div class="homeTitle" align="center">
    <b>{{homeTitle}}</b>
    <p class="homeSubTitle" *ngIf="unauthorizedUser">{{homeUnauthorizedMessage}}</p>

    <app-system-down *ngIf="showSystemDown"
      [title]="systemDownFlagValue.title"
      [message]="systemDownFlagValue.message">
    </app-system-down>

  </div>

  <div id="application-tiles" align="center">
      <fm-cards id="rent-roll-tile" class="tiles" *ngIf="showRentRollDashboard">
        <a (click)="navigateToRentRollDashboard()">Rent Roll<br>Digitizer</a>
      </fm-cards>
      <fm-cards class="tiles" *ngIf="showDueDiligenceDashboard">
        <a (click)="navigateToDDRDashboard()">Due Diligence<br>Reports</a>
      </fm-cards>
      <fm-cards class="tiles" *ngIf="showOperatingStatementsDashboard">
        <a (click)="navigateToOSRDashboard()">Operating Statement</a>
      </fm-cards>
  </div>

</div>

<div id="issueSummaryPreview" class="issueSummaryPreview">
  <div class="preview-split-left" id="file-preview">
    <br>

    <div [ngClass]="{'no-print': printPreview == false}">

      <div id="issue-summary-file-preview" class="issue-summary-file-preview">
        {{filename}}
      </div>

      <div id="issue-summary-file-timestamp" class="issue-summary-file-timestamp">
        <i>{{timestamp}}</i>
      </div>

      <div id="navigation-icons-file-preview" class="navigation-icons-file-preview no-print">
        <i class="fmi fmi-print print-icon" aria-hidden="true" (click)="printTable()" on-mouseover="setPrintSettings('file-preview')"></i>
        <i *ngIf="!expanded" class="fmi fmi-expand expand-icon" aria-hidden="true" (click)="toggleAllRows(previewTable, 'expand')"></i>
        <i *ngIf="expanded" class="fmi fmi-compress compress-icon" aria-hidden="true" (click)="toggleAllRows(previewTable, 'collapse')"></i>
      </div>

      <div id="preview-table" class="preview-table">
        <br>

        <fm-table #previewTable dataKey="displayName" [columns]="genericReportPreviewCols" [value]="displayArray">
          <ng-template fmTemplate="header" let-columns>
            <th style="width: 3em"></th>
            <th *ngFor="let col of columns">{{ col.header }}</th>
          </ng-template>
          <ng-template fmTemplate="body" let-expanded="expanded" let-rowData let-columns="columns">
            <tr>
              <td *ngIf="rowData.expand;else regularRow">
                <a href="#" [fmRowToggler]="rowData">
                  <i [ngClass]="expanded ? 'fmi fmi-chevron-down' : 'fmi fmi-chevron-right'"></i>
                </a>
              </td>
              <ng-template #regularRow><td style="width: 3em"></td></ng-template>
              <td *ngFor="let col of columns">
                <div>
                  {{ rowData[col.field] }}
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template fmTemplate="rowexpansion" let-rowData let-rowIndex="rowIndex" let-columns="columns">
            <tr>
              <td style="width: 3em"></td>
              <td [attr.colspan]="columns.length" style="padding-left: 10%; margin:0" class="customStyles">
                <fm-table *ngIf="!rowData.pcaXmlIRE && !rowData.pcaXmlCICE && !rowData.pcaCsvIRE && !rowData.pcaCsvCICE" [columns]="columns" [value]="rowData.expand" [rowExpand]="true">
                  <ng-template fmTemplate="body" let-rowData let-columns="columns">
                    <tr>
                      <td *ngFor="let col of columns" >
                        {{rowData[col.field]}}
                      </td>
                    </tr>
                  </ng-template>
                </fm-table>
                <fm-table *ngIf="rowData.pcaXmlIRE" [columns]="pcaXmlIREPreviewCols" [value]="displayArrayPcaXmlIRE" [rowExpand]="true" [autoLayout]="true" scrollable="true">
                  <ng-template fmTemplate="header" let-columns>
                    <tr>
                      <th *ngFor="let col of columns">{{col.header}}</th>
                    </tr>
                  </ng-template>
                  <ng-template fmTemplate="body" let-rowData let-columns="columns">
                    <tr>
                      <td *ngFor="let col of columns" [style.width]="col.width">
                        {{rowData[col.field]}}
                      </td>
                    </tr>
                  </ng-template>
                </fm-table>
                <fm-table *ngIf="rowData.pcaXmlCICE" [columns]="pcaXmlCICEPreviewCols" [value]="displayArrayPcaXmlCICE" [rowExpand]="true" scrollable="true">
                  <ng-template fmTemplate="header" let-columns>
                    <tr>
                      <th style="text-align: left" *ngFor="let col of columns">{{col.header}}</th>
                    </tr>
                  </ng-template>
                  <ng-template fmTemplate="body" let-rowData let-columns="columns">
                    <tr>
                      <td *ngFor="let col of columns" [style.width]="col.width">
                        {{rowData[col.field]}}
                      </td>
                    </tr>
                  </ng-template>
                </fm-table>
                <fm-table *ngIf="rowData.pcaCsvIRE" [columns]="pcaCsvIREPreviewCols" [value]="displayArrayPcaCsvIRE" [rowExpand]="true" [autoLayout]="true" scrollable="true">
                  <ng-template fmTemplate="header" let-columns>
                    <tr>
                      <th *ngFor="let col of columns">{{col.header}}</th>
                    </tr>
                  </ng-template>
                  <ng-template fmTemplate="body" let-rowData let-columns="columns">
                    <tr>
                      <td *ngFor="let col of columns" [style.width]="col.width">
                        {{rowData[col.field]}}
                      </td>
                    </tr>
                  </ng-template>
                </fm-table>
                <fm-table *ngIf="rowData.pcaCsvCICE" [columns]="pcaCsvCICEPreviewCols" [value]="displayArrayPcaCsvCICE" [rowExpand]="true" scrollable="true">
                  <ng-template fmTemplate="header" let-columns>
                    <tr>
                      <th style="text-align: left" *ngFor="let col of columns">{{col.header}}</th>
                    </tr>
                  </ng-template>
                  <ng-template fmTemplate="body" let-rowData let-columns="columns">
                    <tr>
                      <td *ngFor="let col of columns" [style.width]="col.width">
                        {{rowData[col.field]}}
                      </td>
                    </tr>
                  </ng-template>
                </fm-table>
              </td>
            </tr>
          </ng-template>
        </fm-table>
      </div>

      <div *ngIf="showReportedFieldsNullMessage" id="reported-files-null" class="reported-files-null">
        <br>
        <i>Data not found in the file.</i>
      </div>
      <br>
    </div>

  </div>
  <div class="preview-split-center no-print">
  </div>
  <div class="preview-split-right" id="errors-and-warnings">
    <br>

    <div [ngClass]="{'no-print': printErrorsWarnings == false}">

      <div id="issue-summary-errors-warnings-title" class="issue-summary-errors-warnings-title">
        Errors and Warnings
      </div>

      <div id="navigation-icons-errors-warnings" class="navigation-icons-errors-warnings no-print">
        <i class="fmi fmi-print print-icon" aria-hidden="true" (click)="printScreen()" on-mouseover="setPrintSettings('errors-and-warnings')"></i>
        <i class="fmi fmi-close close-icon" aria-hidden="true" (click)="return()"></i>
      </div>

      <div>
        <fm-header-template>
          <div id="fileRejectedError" *ngIf="fileRejectedError"><br>
            <i>The file was rejected because of the following error(s). Please correct and resubmit.</i>
          </div>
          <div id="fileRejectedErrorWarning" *ngIf="fileRejectedErrorWarning"><br>
            <i>The file was rejected because of the following error(s). Please correct and resubmit.</i><br>
            <i>The file also contains the following warning(s).</i></div>
          <div id="fileWarning" *ngIf="fileWarning"><br>
            <i>The file was accepted but contains the following warning(s).</i></div>
          <div id="internalError" *ngIf="internalError"><br>
            <i>{{serverErrorMessage}}</i></div>
          <div id="noErrorsWarnings" *ngIf="showNoErrorsWarningsMessage"><br>
            <i>The file has no Errors or Warnings.</i></div>
        </fm-header-template>

        <br>

      </div>

      <div id="masterRecord" *ngIf="masterRecord">
        <div id="masterRecordErrors" *ngIf="showErrorsMaster">
          <div id="pcaMasterErrorsTitle" *ngIf="ifPca"><b>Summary Error(s)</b></div>
          <div id="bpoMasterErrorsTitle" *ngIf="ifBpo"><b>Subject Property Error(s)</b></div>
          <div id="notPcaMasterErrorsTitle" *ngIf="ifOther"><b>Error(s)</b></div>

          <ul>
            <li *ngFor="let error of errorsMaster">
              {{ error }}
            </li>
          </ul>
        </div>

        <div id="masterRecordWarnings" *ngIf="showWarningsMaster">
          <div id="pcaMasterWarningsTitle" *ngIf="ifPca"><b>Summary Warning(s)</b></div>
          <div id="bpoMasterWarningsTitle" *ngIf="ifBpo"><b>Subject Property Warning(s)</b></div>
          <div id="notPcaMasterWarningsTitle" *ngIf="ifOther"><b>Warning(s)</b></div>

          <ul>
            <li *ngFor="let warning of warningsMaster">
              {{ warning }}
            </li>
          </ul>
        </div>
      </div>

      <div id="errors" *ngIf="showErrors">
        <div id="pcaErrorsTitle" *ngIf="ifPca"><b>Immediate Repair and Capital Item Error(s)</b></div>
        <div id="bpoErrorsTitle" *ngIf="ifBpo"><b>Comparable Property and Property Valuation Error(s)</b></div>
        <div id="notPcaErrorsTitle" *ngIf="ifOther"><b>Error(s)</b></div>

        <br>

        <fm-table id="errorsTable" [columns]="cols5" [value]="groupedErrorDisplay">
          <ng-template fmTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">{{col.header}}</th>
            </tr>
          </ng-template>
          <ng-template fmTemplate="body" let-rowData let-columns="columns">
            <tr align="center">
              <td *ngFor="let col of columns">
                {{rowData[col.field]}}
              </td>
            </tr>
          </ng-template>
        </fm-table>
        <br>
      </div>

      <div id="warnings" *ngIf="showWarnings">
        <div id="pcaWarnings" *ngIf="ifPca"><b>Immediate Repair and Capital Item Warning(s)</b></div>
        <div id="bpoWarnings" *ngIf="ifBpo"><b>Comparable Property and Property Valuation Warning(s)</b></div>
        <div id="notPcaWarnings" *ngIf="ifOther"><b>Warning(s)</b></div>

        <br>

        <fm-table id="warningsTable" [columns]="cols6" [value]="groupedWarningDisplay">
          <ng-template fmTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">{{col.header}}</th>
            </tr>
          </ng-template>
          <ng-template fmTemplate="body" let-rowData let-columns="columns">
            <tr align="center">
              <td *ngFor="let col of columns">
                {{rowData[col.field]}}
              </td>
            </tr>
          </ng-template>
        </fm-table>
        <br>
      </div>

      <br>

    </div>

    <div style="text-align: right" class="no-print">
      <button id="returnToFileReviewButton" fmButton type="button" label="Return To File Summary" (click)="return()"
              class="return-button"></button>
    </div>

    <br>
  </div>
</div>

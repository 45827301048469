<div id="operating-statement-dashboard">
  <fm-alert-feedback [alignLeft]="false" [alert]="alertFeedback"></fm-alert-feedback>
  <fm-loading-spinner [isLoading]="isLoading"></fm-loading-spinner>

  <div class="sub-header">
    <div id="navigation-links">
      <fm-breadcrumb [compressed]="true" [links]="navigationLinks" (onLinkClick)="navigate($event)"></fm-breadcrumb>
    </div>
    <div id="lender-name">
      {{lenderName}}
    </div>
  </div>

  <app-user-notification *ngIf="showSystemNotification" [title]="systemNotificationFlagValue.title"
    [message]="systemNotificationFlagValue.message">
  </app-user-notification>  
  <br>

  <div [className]="isInternalPortal ? 'internalLeft' : 'externalLeft'">

    <br>
    <div class="title" align="center">
      Summary
    </div>
    <div id="overview-selection">
      <div class="subtitle">
        Period
      </div>
      <fm-select id="overview-dropdown" [options]="overviewDropdownMenu" [(ngModel)]="selectedOverviewPeriod"
        (onChange)="saveSelectedOverviewPeriod($event)" [placeholder]=selectedOverviewPeriod></fm-select>
      <br>
    </div>

    <div id="top-row">

      <div id="borrower-stats" align="center">
        <div class="stats-top-row">
          <fm-cards>
            <div class="count-value">
              {{totalSponsorsCount | number}}
            </div>
            Total Sponsors
          </fm-cards>
        </div>
        <div class="stats-bottom-row">
          <fm-cards class="stats-bottom-row-left">
            <div class="count-value">
              {{sponsorsRegisteredCount | number}}
            </div>
            Sponsors<br>
            Reported
          </fm-cards>
          <fm-cards class="stats-bottom-row-right">
            <div class="count-value">
              {{sponsorsNotRegisteredCount | number}}
            </div>
            Sponsors<br>
            Not Reported
          </fm-cards>
        </div>
      </div>

      <div id="property-stats">
        <fm-cards class="stats-top-row">
          <div class="count-value">
            {{totalPropertiesCount | number}}
          </div>
          Total Properties
        </fm-cards>
        <div class="stats-bottom-row">
          <fm-cards class="stats-bottom-row-left">
            <div class="count-value">
              {{propertiesReportedCount | number}}
            </div>
            Operating Statements<br>
            Reported
          </fm-cards>
          <fm-cards class="stats-bottom-row-right">
            <div class="count-value">
              {{propertiesNotReportedCount | number}}
            </div>
            Operating Statements<br>
            Not Reported
          </fm-cards>
        </div>
      </div>


    </div>

    <br>
    <br>

    <div id="bottom-row">
      <div class="title2" align="center" *ngIf="!isInternalPortal">
        Sponsor Reporting Status
      </div>
      <div id="lender-portal-table" class="sponsorTable" *ngIf="!isInternalPortal">
        <div *ngIf="initialDashboardDetails.length > 0">
          <fm-table #tbl [columns]="lenderPortalTableCols" [value]="initialDashboardDetails" sortMode="single"
            [paginator]="true" [rows]="25" [rowsPerPageOptions]="[25,30,50,75,100,125,150,200]">
            <ng-template fmTemplate="caption">
              <div style="text-align: left">
                <input placeholder="Type to filter" style="width:auto" fmTextInput type="text"
                  [(ngModel)]="globalFilterBinding" (input)="tbl.filterGlobal($event.target.value, 'contains')" />
              </div>
            </ng-template>
            <ng-template fmTemplate="header" let-columns headerColor="white">
              <tr>
                <th *ngFor="let col of columns" [fmSortableColumn]="col.field">{{col.header}}
                  <fm-sortIcon [field]="col.field"></fm-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template fmTemplate="body" let-rowData let-columns="columns">
              <tr align="center" [fmSelectableRow]="rowData" [fmSelectableRowDisabled]="false"
                (click)="showSponsorPropertyList(rowData)">
                <td class="hyperlink">
                  {{rowData['name']}}
                </td>
                <td>
                  {{rowData['propertiesCnt'] | number}}
                </td>
                <td>
                  {{rowData['propertiesReported'] | number}}
                </td>
                <td>
                  {{rowData['propertiesNotReported'] | number}}
                </td>
              </tr>
            </ng-template>
          </fm-table>
        </div>
        <div *ngIf="initialDashboardDetails.length === 0">
          <fm-table [columns]="lenderPortalTableCols" [value]="initialDashboardDetails" sortMode="single">
            <ng-template fmTemplate="header" let-columns headerColor="white">
              <tr>
                <th *ngFor="let col of columns">{{col.header}}</th>
              </tr>
            </ng-template>
            <ng-template fmTemplate="body" let-rowData let-columns="columns">
              <tr align="center">
                <td *ngFor="let col of columns">
                  {{rowData[col.field]}}
                </td>
              </tr>
            </ng-template>
          </fm-table>
        </div>
        <div class="no-sponsor-data" align="left" *ngIf="initialDashboardDetails.length === 0">
          <i>No sponsor data for the selected summary period. </i>
        </div>
        <br>
      </div>
      <div class="title2" align="center" *ngIf="isInternalPortal">
        Lender Reporting Status
      </div>
      <div id="internal-portal-table" class="sponsorTable" *ngIf="isInternalPortal">
        <div *ngIf="initialDashboardDetails.length > 0">
          <fm-table #tbl [columns]="internalPortalTableCols" [value]="initialDashboardDetails" sortMode="single"
            [paginator]="true" [rows]="25" [rowsPerPageOptions]="[25,30,50,75,100,125,150,200]">
            <ng-template fmTemplate="caption">
              <div style="text-align: left">
                <input placeholder="Type to filter" style="width:auto" fmTextInput type="text"
                  [(ngModel)]="globalFilterBinding" (input)="tbl.filterGlobal($event.target.value, 'contains')" />
              </div>
            </ng-template>
            <ng-template fmTemplate="header" let-columns headerColor="white">
              <tr>
                <th *ngFor="let col of columns" [fmSortableColumn]="col.field">{{col.header}}
                  <fm-sortIcon [field]="col.field"></fm-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template fmTemplate="body" let-rowData let-columns="columns">
              <tr align="center">
                <td (click)="showLenderPortal(rowData)" class="hyperlink">
                  {{rowData['name']}}
                </td>
                <td>
                  {{rowData['sponsorCnt'] | number}}
                </td>
                <td>
                  {{rowData['propertiesCnt'] | number}}
                </td>
                <td>
                  {{rowData['propertiesReported'] | number}}
                </td>
                <td>
                  {{rowData['propertiesNotReported'] | number}}
                </td>
              </tr>
            </ng-template>
          </fm-table>
        </div>
        <div *ngIf="initialDashboardDetails.length === 0">
          <fm-table [columns]="internalPortalTableCols" [value]="initialDashboardDetails" sortMode="single">
            <ng-template fmTemplate="header" let-columns headerColor="white">
              <tr>
                <th *ngFor="let col of columns">{{col.header}}</th>
              </tr>
            </ng-template>
            <ng-template fmTemplate="body" let-rowData let-columns="columns">
              <tr align="center">
                <td *ngFor="let col of columns">
                  {{rowData[col.field]}}
                </td>
              </tr>
            </ng-template>
          </fm-table>
        </div>
        <div class="no-sponsor-data" align="left" *ngIf="initialDashboardDetails.length === 0">
          <i>No sponsor data for the selected summary period. </i>
        </div>
        <br>
      </div>
    </div>

  </div>


  <div class="externalRight" *ngIf="!isInternalPortal">


    <div id="download-tool" align="center">
      <br>
      <fm-cards title="Download Operating Statement Data">
        <div>
          <div id="select-periods">
            <b>Select Time Frame</b><br><br>
            <div id="select-start-period" class="select-style">
              <fm-select [options]="selectStartPeriod" [(ngModel)]="selectedStartPeriod"
                placeholder="{{startPeriodSelectionText}}" (onChange)="getEndPeriodDates()"></fm-select>
            </div>
            <div id="select-end-period" class="select-style">
              <fm-select [options]="selectEndPeriod" [(ngModel)]="selectedEndPeriod"
                (onChange)="filterPropertiesByPeriodDates()" placeholder="{{endPeriodSelectionText}}"></fm-select>
            </div>
          </div>

          <br>
          <br>
          <br>

          <div id="select-properties">
            <b>Select Property</b><br><br>
            <div id="select-property" class="select-style">
              <fm-multiselect ariaLabelledBy="disable" [options]="selectProperty" [(ngModel)]="selectedProperty"
                [filter]="false" defaultLabel="{{propertySelectionText}}" [maxSelectedLabels]="0"
                (onChange)="saveSelectedProperty($event)">
                [virtualScroll]="true"
              </fm-multiselect>
            </div>
          </div>
        </div>

        <br>

        <div align="center">
          <button fmButton [primary]="true" type="button" label="Download" (click)="streamExcel()"
            [disabled]="selectedStartPeriod == '' || selectedEndPeriod == '' || isInternalPortal || disableDownload"></button>
        </div>
      </fm-cards>
    </div>

    <div id="bottom-right" *ngIf="sponsorSelected">
      <div class="title2" align="center">
        {{sponsorSelectedName}}
      </div>
      <div id="sponsor-property-list">
        <fm-table #splt [columns]="sponsorPropertyListCols" [value]="operatingStmtsSponsorPropertyListDisplay"
          sortMode="single" [paginator]="true" [rows]="25" [rowsPerPageOptions]="[25,30,50,75,100,125,150,200]"
          xportFilename="OS_{{ sponsorSelectedName }}_{{ selectedOverviewPeriod }}">
          <ng-template fmTemplate="caption">
            <div style="text-align: right">
              <button fmButton type="button" label="Export" (click)="splt.exportCSV()"></button>
            </div>
          </ng-template>
          <ng-template fmTemplate="header" let-columns headerColor="white">
            <tr>
              <th *ngFor="let col of columns" [fmSortableColumn]="col.field" [style.width]="col.width">
                <span fmTooltip="{{ col.tooltip }}" tooltipPosition="top">{{ col.header }}</span>
                <fm-sortIcon [field]="col.field"></fm-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template fmTemplate="body" let-rowData let-columns="columns">
            <tr align="center">
              <td>
                {{rowData['propNme']}}
              </td>
              <td>
                {{rowData['grsPotlRntAmt'] | currency : 'USD' : 'symbol' : '1.0-0'}}
              </td>
              <td>
                {{rowData['effectiveGrsIncome'] | currency : 'USD' : 'symbol' : '1.0-0'}}
              </td>
              <td>
                {{rowData['totalOperatingExpenses'] | currency : 'USD' : 'symbol' : '1.0-0'}}
              </td>
              <td>
                {{rowData['netOperatingIncome'] | currency : 'USD' : 'symbol' : '1.0-0'}}
              </td>
            </tr>
          </ng-template>
        </fm-table>
      </div>
      <div class="no-sponsor-data" align="left" *ngIf="operatingStmtsSponsorPropertyListDisplay.length === 0">
        <i>No operating statements submitted for the selected sponsor. </i>
      </div>
    </div>

  </div>


</div>
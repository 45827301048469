<div *ngIf="userName.firstName || userName.lastName; else userInfoNoAvail">
  <fm-header
    [profileMenuItems]="items"
    [smallLogo]="false"
    currentApp="DUS Data Digitizer™"
    (iconClicked)="onIconClick()"
    [userName]="userName"
    [noFixed]="true"
    [homeUrl]="'#'">
  </fm-header>
</div>

<ng-template #userInfoNoAvail>
  <fm-header
    [profileMenuItems]="items"
    [smallLogo]="false"
    currentApp="DUS Data Digitizer™"
    (iconClicked)="onIconClick()"
    [noFixed]="true"
    [homeUrl]="'#'">
  </fm-header>
</ng-template>

<fm-dialog id="updateAcqnModal" #dialog2 [alignLeft]="true"
  header="Update Report" [(visible)]="showAcqnUpdateComponent" [responsive]="true" [dismissableMask]="true"
   (onHide)="onCancelled()">
  <fm-loading-spinner [isLoading]="isLoading"></fm-loading-spinner>

  <br>

  <div class="top-label-1"><b>Note:</b> Click on N or N/A to toggle the value.  You can toggle N to N/A or N/A to N.</div>
  <div class="top-label-2">** denotes it is modified.</div>

  <div>
    <fm-table [columns]="columnHeaders" [value]="selectedReportArrayDisplay" [responsive]="false">
      <ng-template fmTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{ col.header }}</th>
        </tr>
      </ng-template>

      <ng-template fmTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex" let-columns="columns">
        <tr align="center" [fmEditableRow]="rowData">
          <td>
            {{ rowData.propNme }}
          </td>
          <td *ngFor="let col of (columns | slice: 1)">
            <div fmEditableColumn *ngIf="rowData[col.field] != 'Y'">
              <fm-cellEditor>
                <ng-template fmTemplate="input">
                  <fm-input-switch leftLabel="N" rightLabel="N/A" [(ngModel)]="rowData[col.toggleFlag]"
                    (onChange)="onChangeFlagValue($event, rowData, col.field, col.isModified)"></fm-input-switch>
                </ng-template>
                <ng-template fmTemplate="output">
                  <span *ngIf="! rowData[col.isModified]">{{ rowData[col.field] }}</span>
                  <span *ngIf="rowData[col.isModified]"> ** {{ rowData[col.field] }}</span>
                </ng-template>
              </fm-cellEditor>
            </div>
            <div *ngIf="rowData[col.field] == 'Y'">
              {{ rowData[col.field] }}
            </div>
          </td>
        </tr>
      </ng-template>
    </fm-table>

    <br>
    <div style="text-align: left">
      <button class="back-upload-button" fmButton type="button" label="Cancel" (click)="onCancelled()"></button>
    </div>
    <div class="finish-close-button">
      <i class="submissionText">Changes made will be persisted.</i>
      <button fmButton type="button" [primary]="true" label="Submit Changes" (click)="onFinishAndClosed()"></button>
      <div style="text-align: right; color: red; padding-top: 10px">
        {{errorMessageSelection}}
      </div>
    </div>
  </div>

  <br>
  <div style="text-align: center; color: red; padding-top: 40px">
    {{errorMessageAPI}}
  </div>

  <fm-loading-spinner [isLoading]="showSpinner"></fm-loading-spinner>

</fm-dialog>
